import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLng } from '@yesplz/core-redux/ducks/admin/auth';
import usFlag from '../assets/images/us-flag.svg';
import krFlag from '../assets/images/kr-flag.svg';

const FLAGS = {
  en: usFlag,
  ko: krFlag,
};

export default function LngSelector() {
  const { lngs, lng } = useSelector(({ AdminAuth: { userInfo: { lngs }, lng } }) => ({ lngs, lng }));
  const dispatch = useDispatch();

  return (
    <>
      {lngs.map(item => (
        <button
          key={item.lng}
          className={classNames({
            'is-active': item.lng === lng,
          })}
          onClick={() => dispatch(setLng(item.lng))}
        ><img src={FLAGS[item.lng]} alt={item.label} /></button>
      ))}
    </>
  );
}
