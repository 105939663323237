import designSolidSvg from '@yesplz/core-models/assets/svg/design-solid.svg';
import designPatternSvg from '@yesplz/core-models/assets/svg/design-pattern.svg';
import designDetailSvg from '@yesplz/core-models/assets/svg/design-detail.svg';
import designDestroyedSvg from '@yesplz/core-models/assets/svg/design-destroyed.svg';
import designLaceUpSvg from '@yesplz/core-models/assets/svg/design-lace-up.svg';
// import designLightSvg from '@yesplz/core-models/assets/svg/design-light.svg';
import designLeatherSvg from '@yesplz/core-models/assets/svg/design-leather.svg';
import designDenimSvg from '@yesplz/core-models/assets/svg/design-denim.svg';
import designSlacksSvg from '@yesplz/core-models/assets/svg/design-slacks.svg';
import designWoolSvg from '@yesplz/core-models/assets/svg/design-wool.svg';
import designDownSvg from '@yesplz/core-models/assets/svg/design-down.svg';
// import designWorkoutSvg from '@yesplz/core-models/assets/svg/design-workout.svg';
// import designLeggingsSvg from '@yesplz/core-models/assets/svg/design-leggings.svg';
import designSuedeSvg from '@yesplz/core-models/assets/svg/design-suede.svg';
import designAnimalSvg from '@yesplz/core-models/assets/svg/design-animal.png';
import designCottonSvg from '@yesplz/core-models/assets/svg/design-cotton.svg';
// import materialFleeceSvg from '@yesplz/core-models/assets/svg/material-fleece.svg';
import materialGoldSvg from '@yesplz/core-models/assets/svg/material-gold.svg';
import materialRoseGoldSvg from '@yesplz/core-models/assets/svg/material-rose-gold.svg';
import materialSilverSvg from '@yesplz/core-models/assets/svg/material-silver.svg';
import materialPearlSvg from '@yesplz/core-models/assets/svg/material-pearl.svg';

// For local testing
var vf_svg_url = function (path) { return require('@yesplz/core-models/assets/svg/'+path) }

export const designFilterParams = {
  designTypes: [
    {type: 'solid', label: 'Solid', image: designSolidSvg, remove: 'pattern'},
    {type: 'pattern', label: 'Pattern', image: designPatternSvg, remove: 'solid'},
    {type: 'detail', label: 'Details', image: designDetailSvg, activeIn: ['wtop', 'wknit', 'wdress', 'wouter', 'wpants', 'wskirt', 'wdressshoes', 'wsneakers', 'wbag', 'wwallet', 'wearring']},
    {type: 'ripped-off', label: 'Destroyed', image: designDestroyedSvg, activeIn: ['wpants', 'mpants']},
    {type: 'lace-up', label: 'Shoelaces', image: designLaceUpSvg, activeIn: ['wdressshoes']},
    {type: 'animal', label: 'Animal Print', image: designAnimalSvg, activeIn: ['wwallet', 'mwallet']},
  ]
};

export const materialFilterParams = {
  materialTypes: [
    // {type: 'light', label: 'Light', image: designLightSvg, activeIn: ['wouter', 'mouter']},
    {type: 'leather', label: 'Leather', image: designLeatherSvg, activeIn: ['wouter', 'wdressshoes', 'mouter', 'mshoes', 'mdressshoes', 'wwallet', 'mwallet']},
    {type: 'denim', label: 'Denim', image: designDenimSvg, activeIn: ['wpants', 'mpants']},
    {type: 'cotton', label: 'Cotton', image: designCottonSvg, activeIn: ['wpants', 'mpants']},//'mouter', 'wouter'
    {type: 'wool', label: 'Wool', image: designWoolSvg, activeIn: ['wpants', 'mpants']}, //'mouter', 'wouter'
    {type: 'down', label: 'Down/Puffer', image: designDownSvg, activeIn: ['wouter', 'mouter']},
    // {type: 'workout', label: '운동/아웃도어', image: designWorkoutSvg, activeIn: ['wpants', 'mpants']},
    // {type: 'polyester', label: 'Polyester', image: designWoolSvg, activeIn: ['wpants']}, // TODO: Update image
    {type: 'suede', label: 'Suede', image: designSuedeSvg, activeIn: ['wdressshoes', 'mshoes', 'mdressshoes']},
    {type: 'fabric', label: 'Fabric', image: designSlacksSvg, activeIn: ['mwallet']},
    // {type: 'fleece', label: 'Fleece', image: materialFleeceSvg, activeIn: ['mouter', 'wouter']},
    {type: 'gold', label: 'Gold', image: materialGoldSvg, activeIn: ['wearring']},
    {type: 'rosegold', label: 'Rose Gold', image: materialRoseGoldSvg, activeIn: ['wearring']},
    {type: 'silver', label: 'Silver', image: materialSilverSvg, activeIn: ['wearring']},
    {type: 'pearl', label: 'Pearl', image: materialPearlSvg, activeIn: ['wearring']},
  ]
};

export const paramLabelsParams = {
  showPartNameFor: {
    'wtop': ['sleeves'],
    'wdress': ["sleeves"],
    'mouter': ['length'],
    'mknit': ['sleeves'],
    'wpants': ["length", "ankle"],
    'mpants': ["ankle"],
    'wdressshoes': ["counters"],
    'wwallet': ['size', 'closure'],
    'wearring': ['shape', 'size'],
    'wbag': ['size_h', 'handle', 'closure'],
    'mbag': ['size_h'],
  },
  labels: {
    'wtop': {
      "neckline": { "open": "Open Neck", "round": "Round Neck", "high": "High Neck" },
      'waist': { 'straight': 'Regular Fit', 'wide': 'Loose Fit' },
      'length': { 'short': "Regular Length", "long": "Long Length"}
    },
    'wdress': {
      "neckline": { "open": "Open Neck", "round": "Round Neck", "high": "High Neck" },
      'waist': { 'wide': 'Loose Fit' },
      "style": {"plain": "Plain Style"},
      "length": {"short":"Short Length", "knees":"Knee Length", "semi_long": "Midi", "very_long": "Max"}
    },
    'wknit': {
      "neckline": { "open": "Open Neck", "round": "Round Neck", "high": "High Neck" },
      "sleeves": { "short": "Short Sleeves", "long": "Long Sleeves"},
      "short": {"short": "Short Length", "medium": "Regular Length", "long": "Long Length"}
    },
    'wskirt': {
      "thigh": {"tight": "Fitted", "wide": "Loose Fit"},
      "style": {"plain": "Plain Style"},
      "length": {"short":"Short Length", "knees":"Knee Length", "semi_long": "Midi", "very_long": "Max"}
    },
    'wpants': {
      'tightness': {"tight": "Skinny", "straight": "Medium Fit", "wide": "Loose Fit"}
    },
    'wouter': {
      "neckline": { "round": "Round Neck", "Wide": "Wide Collar", "high": "High Neck" },
      'waist': { 'wide': 'Loose Fit' },
      "sleeves": { "short": "Short Sleeves", "long": "Long Sleeves"},
      'length': { 'short': "Short Length", "half": "Regular Length", "long": "Long Length"}
    },
    'wdressshoes': {
      "shafts": {"open": "Open Neck", "ankles": "Ankle Boots", "knees": "High Boots"},
      "toes": {"open": "Open Toes", "pointy": "Pointed Toes", "round": "Round Toes"}
    },
    'wsneakers': {
      "toes": {"pointy": "Pointed Toes", "round": "Round Toes", "flat": "Flat Toes"},
      "sole": {"low": "Flat Sole", "grip": "Grip Sole", "chunky": "Chunky Sole"},
    },
    'mtshirts': {
      "neckline": { "flat": "Round Neck", "high": "High Neck" },
      'waist': { 'straight': 'Regular Fit', 'wide': 'Loose Fit' },
      'sleeves': { 'short': 'Short Sleeves', 'long': 'Long Sleeves'}
    },
    'mshirts': {
      "neckline": { "short": "Regular Collar", "large": "Wide Collar" },
      "sleeves": {"short": "Short Sleeves", "long": "Long Sleeves"},
      'waist': { 'wide': 'Loose Fit' },
    },
    'mknit': {
      "neckline": { "flat": "Round Neck", "high": "High Neck" }
    },
    'mpants': {
      'tightness': { 'straight': 'Regular Fit', 'wide': 'Loose Fit' },
      'length': {'long': 'Regular Length'},
      'waist': { 'wide': 'Loose Fit' }
    },
    'mouter': {
      'neckline': { "low": "Low Neck", "high": "High Neck"},
      'sleeves': {"short": "Short Sleeves", "long": "Long Sleeves"},
      'waist': { 'wide': 'Loose Fit' }
    },
    'mdressshoes': {
      'toes': {'pointy': "Pointed Toes", "round": "Round Toes", "apron": "Apron Toes"},
      "sole": {"solid": "Flat Sole", "chunky": "Grip Sole"},
      "shoestyle": {"plain": "Plain Style"},
      "neck": {"open": "Open Neck", "ankle": "Ankle Boots", "high": "High Boots"}
    },
    'msneakers': {
      'toes': {'pointy': "Pointed Toes", "round": "Round Toes", "flat": "Flat Toes"},
      "sole": {"low": "Flat Sole", "grip": "Grip Sole", "chunky": "Chunky Sole"},
      "cover": {"plain": "Slip on"}
    },
    'mwallet': {
      'size': {'half': "Half Size", "long": "Long Size", "tall": "Tall Size"},
      'style': {'plain': "Plain Style"}
    },
    'mbag': {
      "handle": {"none": "No Handle", "medium": "Hand Held"},
      "style": {"open": "No Closure"}
    }
  },
};

export const categories = {
  "wtop": {
    "label": "Women Tops",
    "category": "wtop",
    "partList" : ["neckline", "sleeves", "waist", "length"],
    "partListLabels" : ["Neckline", "Sleeves", "Waist", "Length"],
    "defaultVal": {"neckline": "round", "sleeves": "short", "waist": "tight", "length": "short"},
    "presetList": {
      "Comfy":   {"neckline": "round", "sleeves": "short",      "waist": "wide",    "length": "short"},
      "Tank Top":    {"neckline": "round", "sleeves": "wide_strap", "waist": "straight", "length": "long"},
      "Off Top":{"neckline": "open",  "sleeves": "long",       "waist": "tight",    "length": "short"},
      "Cami":        {"neckline": "v_neck","sleeves": "thin_strap","waist": "straight", "length": "short"},
      // "Hoodie":      {"neckline": "hoodie","sleeves": "long",       "waist": "wide",     "length": "short"},
      "Tunic":       {"neckline": "round", "sleeves": "long",       "waist": "straight", "length": "long"}
    },
    "vfSvgSource": vf_svg_url('white/vf_wtop_core.svg'),
    "tnSprite": vf_svg_url('white/TN-Women-Tops-2.0.svg'),
    "tn": {
      "length": {
        "cropped": {"label":"Cropped", "tnClass": "tn-3-0"},
        "short": {"label": "Regular","tnClass": "tn-3-1"},
        "long": {"label": "Long","tnClass": "tn-3-2"}
      },
      "neckline": {
        "open": {"label": "Open","tnClass": "tn-0-0"},
        "v_neck": {"label": "V-neck","tnClass": "tn-0-1"},
        "round": {"label": "Round","tnClass": "tn-0-2"},
        "high": {"label": "High","tnClass": "tn-0-3"},
        "collar": {"label": "Collar","tnClass": "tn-0-4"},
        // "hoodie": {"label": "Hoodie","tnClass": "tn-0-5"}
      },
      // "shoulder": {
      //   "open": {"label": "Open", "tnClass": "tn-1-0"},
      //   "thin": {"label": "Thin","tnClass": "tn-1-1"},
      //   "wide": {"label": "Wide","tnClass": "tn-1-2"},
      //   "closed": {"label": "Closed","tnClass": "tn-1-3"}
      // },
      "sleeves": {
        "thin_strap": {"label": "Cami","tnClass": "tn-1-0"},
        "wide_strap": {"label": "Tank","tnClass": "tn-1-1"},
        "short": {"label": "Short","tnClass": "tn-1-2"},
        "elbow": {"label": "Elbow","tnClass": "tn-1-3"},
        "three_quarters": {"label": "3/4","tnClass": "tn-1-4"},
        "long": {"label": "Long","tnClass": "tn-1-5"},
      },
      "waist": {
        "tight": {"label": "Fitted","tnClass": "tn-2-0"},
        "straight": {"label": "Regular","tnClass": "tn-2-1"},
        "wide": {"label": "Loose","tnClass": "tn-2-2"}
      }
    },
    overrideBodyPartGroupName: function (state, prop, val) {
      // if (prop === 'shoulder') {
      //   if (val === 'closed' && state['sleeves'] === 'sleeveless') {
      //     return 'shoulder_closed_for_sleeves_sleeveless'
      //   }
      // }
      if (prop === 'length') { // && ['short', 'long'].includes(val)) {
        return 'length_' + val + '_waist_' + state['waist']
      }
      if (prop === 'waist') {
        return 'no_display_group'
      }
      if (prop === 'neckline') {
        if (['round', 'v_neck'].includes(val) && state['sleeves'] === 'thin_strap') {
          return 'neckline_' + val + '_' +  state['sleeves']
        }
        if (val === 'collar') {
          return 'neckline_collar_length_' + state['length']
        }
        if (val === 'open' && ['thin_strap', 'wide_strap'].includes(state['sleeves'])) {
          return 'no_display_group'
        }
      }
      if (prop === 'sleeves') {
        if (['thin_strap', 'wide_strap'].includes(val) && state['neckline'] === 'open') {
          return 'sleeves_' + val + '_neckline_open'
        }
      }
      return null
    }
  },
  "wdress": {
    "label": "Women Dresses",
    "category": "wdress",
    "partList": ["neckline", "sleeves","length","style", "waist"],
    "partListLabels" : ["Neckline", "Sleeves", "Length", "Style", "Waist"],
    "defaultVal": {"neckline": "v_neck", "waist": "tight", "sleeves": "long", "length": "semi_long", "style": "plain"},
    "presetList": {
      "Maxi":    {"neckline": "v_neck", "waist": "tight", "sleeves": "elbow", "length": "very_long", "style": "pleated"},
      "Shirt":   {"neckline": "collar", "waist": "tight", "sleeves": "elbow", "length": "semi_long", "style": "plain"},
      "Body-con":{"neckline": "round", "waist": "tight", "sleeves": "thin_strap", "length": "short", "style": "plain"},
      "Slit":    {"neckline": "round", "waist": "tight", "sleeves": "short", "length": "semi_long", "style": "slit"},
      "T-shirt": {"neckline": "round", "waist": "wide", "sleeves": "elbow", "length": "short", "style": "plain"},
      "Slip":    {"neckline": "v_neck", "waist": "wide", "sleeves": "thin_strap", "length": "semi_long", "style": "plain"}
    },
    "vfSvgSource": vf_svg_url('white/vf_wdress_core.svg'),
    "tnSprite": vf_svg_url('white/TN-Women-Dress-2.0.svg'),
    "tn": {
      "neckline": {
        "open": {"label": "Off-Shoulder","tnClass": "tn-0-0"},
        "v_neck": {"label": "V-Neck","tnClass": "tn-0-1"},
        "round": {"label": "Round","tnClass": "tn-0-2"}, // round + square
        "collar": {"label": "Collar","tnClass": "tn-0-3"},
        "high": {"label": "High","tnClass": "tn-0-4"},
      },
      // "shoulder": {
      //   "open": {"label": "Open", "tnClass": "tn-1-0"},
      //   "thin": {"label": "Thin","tnClass": "tn-1-1"},
      //   "wide": {"label": "Wide","tnClass": "tn-1-2"},
      //   "closed": {"label": "Closed","tnClass": "tn-1-3"}
      // },
      "sleeves": {
        "thin_strap": {"label": "Cami","tnClass": "tn-1-0"},
        "wide_strap": {"label": "Tank","tnClass": "tn-1-1"},
        "short": {"label": "Short","tnClass": "tn-1-2"},
        "elbow": {"label": "Elbow","tnClass": "tn-1-3"},
        "three_quarters": {"label": "3/4","tnClass": "tn-1-4"},
        "long": {"label": "Long","tnClass": "tn-1-5"},
      },
      "length": {
        "short": {"label": "Short","tnClass": "tn-3-0"},
        "knees": {"label": "Knees","tnClass": "tn-3-1"},
        "semi_long": {"label": "Midi","tnClass": "tn-3-2"},
        "very_long": {"label": "Long","tnClass": "tn-3-3"}
      },
      "style": {
        "plain": {"label": "Plain","tnClass": "tn-4-0"},
        "pleated": {"label": "Pleated","tnClass": "tn-4-1"},
        "slit": {"label": "Slit","tnClass": "tn-4-2"},
        "ruffle": {"label": "Ruffle","tnClass": "tn-4-3"}
      },
      "waist": {
        "tight": {"label": "Fitted","tnClass": "tn-2-0"}, // tight + straight
        // "straight": {"label": "Regular","tnClass": "tn-2-1"},
        "wide": {"label": "Loose","tnClass": "tn-2-1"}
      }
    },
    overrideBodyPartGroupName (state, prop, val) {
      if (prop === 'style' && val !== 'plain') {
        return 'style_' + val + '_for_length_' + state['length']
      }
      if (prop === 'shoulder' && val === 'closed') {
        if (state['sleeve'] === 'none') {
          return 'shoulder_closed_for_sleeve_none'
        }
      }
      if (prop === 'neckline') {
        if (val === 'open' && ['thin_strap', 'wide_strap'].includes(state['sleeves'])) {
          return 'no_display_group'
        } else if ((val === 'round' || val === 'v_neck') &&  state['sleeves'] == 'thin_strap') {
          return 'neckline_' + val + '_sleeves_thin_strap'
        }
      }
      if (prop === 'sleeves') {
        if (['thin_strap', 'wide_strap'].includes(val) && state['neckline'] === 'open') {
          return 'sleeves_' + val + '_neckline_open'
        }
      }
      return null
    },
  },
  "wouter": {
    "label": "Women Outerwears",
    "category": "wouter",
    "partList": ["necktype","length","sleeves","waist"],
    "partListLabels" : ["Necktype", "Length", "Sleeves", "Waist"],
    "defaultVal": {"necktype": "collar", "sleeves": "long","waist": "tight", "length": "long"},
    "presetList": {
      "Blazer":    {"necktype": "notch",  "sleeves": "long", "waist": "tight", "length": "half", "material": "wool"},
      "Overcoat":  {"necktype": "notch",  "sleeves": "long", "waist": "wide", "length": "long", "material": "wool"},
      "Jackets":   {"necktype": "collar",  "sleeves": "long", "waist": "tight", "length": "short"},
      "Parka":     {"necktype": "hoodie", "sleeves": "long", "waist": "wide", "length": "long", "material": "down"},
      "Pullover":  {"necktype": "high",   "sleeves": "long", "waist": "wide", "length": "half", "material": "fleece"},
      "Moto":      {"necktype": "notch",  "sleeves": "long", "waist": "tight", "length": "short", "material": "leather"}
    },
    "vfSvgSource": vf_svg_url('white/vf_wouter_core.svg'),
    "tnSprite": vf_svg_url('white/TN-Women-Outerwears-2.0.svg'),
    "tn": {
      "necktype": {
        "notch": {"label": "Notch","tnClass": "tn-0-0"},
        "round": {"label": "Round","tnClass": "tn-0-1"},
        "collar": {"label": "Collar","tnClass": "tn-0-2"},
        "hoodie": {"label": "Hoodie","tnClass": "tn-0-3"},
        "high": {"label": "High","tnClass": "tn-0-4"},
        "wide_collar": {"label": "Wide","tnClass": "tn-0-5"}
      },
      "length": {
        "short": {"label": "Short","tnClass": "tn-1-0"},
        "half": {"label": "Regular","tnClass": "tn-1-1"},
        "long": {"label": "Long","tnClass": "tn-1-2"}
      },
      "waist": {
        "tight": {"label": "Fitted","tnClass": "tn-3-0"},
        // "straight": {"label": "Regular","tnClass": "tn-3-1"},
        "wide": {"label": "Loose","tnClass": "tn-3-1"}
      },
      "sleeves": {
        "sleeveless": {"label": "Sleeveless","tnClass": "tn-2-0"},
        "short": {"label": "Short","tnClass": "tn-2-1"},
        "long": {"label": "Regular","tnClass": "tn-2-2"}
      }
    },
    // "extraBodyGroups": ["sleeves_frame"],
    overrideBodyPartGroupName (state, prop, val) {
      if (prop === 'length') {
        return 'length_' + val + '_waist_' + state['waist']
      }
      if (prop === 'waist') {
        return 'no_display_group'
      }
      if (prop === 'sleeves' && val === 'sleeveless') {
        return 'sleeves_sleeveless_waist_' + state['waist']
      }
      return null
    }
  },
  "wknit": {
    "label": "Women Knits",
    "category": "wknit",
    "partList": ["cardigan","neckline","sleeves","waist","length"],
    "partListLabels" : ["Cardigan/Sweater", "Neckline", "Sleeves", "Waist", "Length"],
    "defaultVal": {"neckline": "round", "sleeves": "long", "waist": "tight", "length": "medium", "cardigan": "sweater"},
    "presetList": {
      "High Neck": {"neckline": "high",   "sleeves": "long", "waist": "tight", "length": "medium", "cardigan": "sweater", "design":"solid"},
      "Cardigan":  {"neckline": "v_neck", "sleeves": "long", "waist": "tight", "length": "long", "cardigan": "cardigan"},
      "Sweater":   {"neckline": "round",  "sleeves": "short", "waist": "tight", "length": "medium", "cardigan": "sweater"},
      "Cropped":   {"neckline": "v_neck", "sleeves": "short", "waist": "tight", "length": "short", "cardigan": "cardigan"},
      "Oversized": {"neckline": "round",  "sleeves": "long", "waist": "wide", "length": "medium", "cardigan": "sweater"},
      "Tank":      {"neckline": "v_neck", "sleeves": "sleeveless", "waist": "tight", "length": "medium", "cardigan": "sweater"}
    },
    "vfSvgSource": vf_svg_url('white/vf_wknit_core.svg'),
    "tnSprite": vf_svg_url('white/TN-Women-Knits-2.0.svg'),
    "tn": {
      "neckline": {
        "v_neck": {"label": "V-neck","tnClass": "tn-1-0"},
        "round": {"label": "Round","tnClass": "tn-1-1"},
        "high": {"label": "High","tnClass": "tn-1-2"}
      },
      "waist": {
        "tight": {"label": "Fitted","tnClass": "tn-3-0"},
        // "straight": {"label": "Regular","tnClass": "tn-3-0"},
        "wide": {"label": "Loose","tnClass": "tn-3-1"}
      },
      "length": {
        "short": {"label": "Short","tnClass": "tn-4-0"},
        "medium": {"label": "Regular","tnClass": "tn-4-1"},
        "long": {"label": "Long","tnClass": "tn-4-2"}
      },
      "sleeves": {
        "sleeveless": {"label": "Sleeveless","tnClass": "tn-2-0"},
        "short": {"label": "Short","tnClass": "tn-2-1"},
        "long": {"label": "Long","tnClass": "tn-2-2"}
      },
      "cardigan": {
        "sweater": {"label": "Sweater","tnClass": "tn-0-0"},
        "cardigan": {"label": "Cardigan","tnClass": "tn-0-1"}
      }
    },
    overrideBodyPartGroupName (state, prop, val) {
      if (prop === 'length') {
        return 'length_' + val + '_waist_' + state['waist']
      }
      if (prop === 'neckline') {
        return 'neckline_' + val + '_' + state['cardigan']
      }
      if (prop === 'waist' || prop === 'cardigan') {
        return 'no_display_group'
      }
      return null
    }

  },
  "wpants": {
    "label": "Women Pants",
    "category": "wpants",
    "partList" : ["rise", "length", "tightness", "ankle"],
    "partListLabels" : ["Rise", "Length", "Tightness", "Ankle"],
    "defaultVal" : {"rise": "medium_high", "tightness": "straight", "length": "cropped",  "ankle": "straight"},
    "presetList": {
      "Skinny": {"rise": "low", "tightness": "tight", "length": "long",  "ankle": "tight", "material": "denim"},
      "Slacks": {"rise": "low", "tightness": "straight", "length": "long",  "ankle": "straight", "material": "wool"},
      "Boot Cut": {"rise": "low", "tightness": "tight", "length": "long",  "ankle": "straight"},
      "Jogger": {"rise": "low", "tightness": "wide", "length": "long",  "ankle": "tight", "material": "cotton"},
      "Leggings": {"rise": "low", "tightness": "tight", "length": "cropped",  "ankle": "tight"},
      "Wide": {"rise": "low", "tightness": "wide", "length": "long",  "ankle": "wide"}
    },
    "vfSvgSource": vf_svg_url('white/vf_wpants_core.svg'),
    "tnSprite": vf_svg_url('white/TN-Women-Pants-v2.0.svg'),
    "tn" : {
      "rise": {
        "low": { "label": "Low Rise", "tnClass": "tn-0-0" },
        "medium_high": { "label": "Mid-High Rise", "tnClass": "tn-0-1" }
      },
      "length": {
        "short": { "label": "Shorts", "tnClass": "tn-1-0" },
        "cropped": { "label": "Cropped", "tnClass": "tn-1-1" },
        "long": { "label": "Regular", "tnClass": "tn-1-2" }
      },
      "tightness": {
        "tight": { "label": "Skinny", "tnClass": "tn-2-0" },
        "straight": { "label": "Slim", "tnClass": "tn-2-1" },
        "wide": { "label": "Loose", "tnClass": "tn-2-2" }
      },
      "ankle": {
        "tight": { "label": "Narrow", "tnClass": "tn-3-0" },
        "straight": { "label": "Straight", "tnClass": "tn-3-1" },
        "wide": { "label": "Wide", "tnClass": "tn-3-2" }
      }
    },
    evaluateDisabledParts(filter) {
      const disabled = [];
      if (filter['length'] === 'short' || filter['length'] === 'knee') {
        disabled.push('ankle');
      }
      return disabled;
    },
    overrideBodyPartGroupName: function (state, prop, val) {
      if (prop === 'length') {
        return 'length_' + val + '_tightness_' + state['tightness']
      }
      if (prop === 'ankle') {
        if (val == 'short') {
          return 'no_display_group'
        }
        return 'ankle_' + val + '_length_' + state['length'] + '_tightness_' + state['tightness']
      }
      if (prop === 'tightness') {
        return 'no_display_group'
      }
      return null
    }
  },
  "wskirt": {
    "label": "Women Skirts",
    "category": "wskirt",
    "partList": ["length","thigh","style"],
    "partListLabels" : ["Length", "Tightness", "Style"],
    "defaultVal": {"thigh": "wide", "length": "knees", "style": "plain"},
    "presetList": {
      "Midi": {"thigh": "wide", "length": "semi_long", "style": "plain"},
      "Pleated": {"thigh": "wide", "length": "very_long", "style": "pleated"},
      "Ruffle": {"thigh": "wide", "length": "knees", "style": "ruffle"},
      "Long": {"thigh": "wide", "length": "very_long", "style": "plain"},
      "Mini": {"thigh": "tight", "length": "short", "style": "plain"},
      "Slit": {"thigh": "tight", "length": "knees", "style": "slit"},
    },
    "vfSvgSource": vf_svg_url('white/vf_wskirt_core.svg'),
    "tnSprite": vf_svg_url('white/TN-Women-Skirts-2.0.svg'),
    "tn": {
      "length": {
        "short": {"label": "Short","tnClass": "tn-1-0"},
        "knees": {"label": "Knees","tnClass": "tn-1-1"},
        "semi_long": {"label": "Midi","tnClass": "tn-1-2"},
        "very_long": {"label": "Long","tnClass": "tn-1-3"}
      },
      "thigh": {
        "tight": {"label": "Fitted","tnClass": "tn-2-0"},
        "wide": {"label": "Loose","tnClass": "tn-2-1"}
      },
      "style": {
        "plain": {"label": "Plain","tnClass": "tn-3-0"}, // Merged with slit, flare in Kolon
        "pleated": {"label": "Pleated","tnClass": "tn-3-1"},
        "slit": {"label": "Slit","tnClass": "tn-3-2"},
        "ruffle": {"label": "Ruffle","tnClass": "tn-3-3"}
      }
    },
    overrideBodyPartGroupName (state, prop, val) {
      if (prop === 'length') {
        return 'length_' + val + '_thigh_' + state['thigh']
      }
      if (prop === 'style') {
        if (['pleated', 'ruffle'].includes(val)) {
          return 'style_' + val + '_length_' + state['length'] + '_thigh_' + state['thigh']
        } else if (val === 'slit') {
          return 'style_' + val + '_length_' + state['length']
        } else {
          return 'style_' + val
        }
      }
      return null
    }
  },
  "wdressshoes": {
    "label": "Women Dress Shoes",
    "category": "wdressshoes",
    "partList": ["toes", "shafts", "counters", "bottoms"],
    "partListLabels" : ["Toes", "Shafts", "Counters", "Bottoms"],
    "defaultVal": {"toes": "round", "shafts": "open", "counters": "full", "bottoms": "low_block"},
    "vfSvgSource": vf_svg_url('white/vf_wdressshoes_core.svg'),
    "tnSprite": vf_svg_url('white/TN-Women-DressShoes-2.0.svg'),
    "presetList": {
      "Stiletto":{"toes": "pointy", "shafts": "open", "counters": "full", "bottoms": "stiletto"},
      "Sandal":  {"toes": "open", "shafts": "open", "counters": "open", "bottoms": "kitten"},
      "Flat":    {"toes": "pointy", "shafts": "open","counters": "full","bottoms": "flat"},
      "Bootie":  {"toes": "pointy", "shafts": "ankles","counters": "full","bottoms": "low_block"},
      "Boot":    {"toes": "pointy", "shafts": "knees","counters": "full","bottoms": "low_block"},
      "Shootie": {"toes": "open", "shafts": "ankles","counters": "open","bottoms": "low_block"}
    },
    "tn": {
      "toes": {
        "open": {"label": "Open","tnClass": "tn-2-0"},
        "pointy": {"label": "Pointed","tnClass": "tn-2-1"},
        "round": {"label": "Round","tnClass": "tn-2-2"}
      },
      "bottoms": {
        "flat": {"label": "Flat","tnClass": "tn-0-0"},
        "wedge": {"label": "Wedge","tnClass": "tn-0-1"},
        "low_block": {"label": "Low Block","tnClass": "tn-0-2"},
        "high_block": {"label": "High Block","tnClass": "tn-0-3"},
        "kitten": {"label": "Low heel","tnClass": "tn-0-4"},
        "stiletto": {"label": "High heel","tnClass": "tn-0-5"}
      },
      // "covers": {
      //   "low": {"label": "Little","tnClass": "tn-2-0"},
      //   "full": {"label": "Full","tnClass": "tn-2-1"},
      //   // "strap": {"label": "스트랩","tnClass": "tn-4-1"}
      // },
      "shafts": {
        "open": {"label": "Regular","tnClass": "tn-3-0"},
        "strap": {"label": "Strap","tnClass": "tn-3-1"},
        "ankles": {"label": "Ankle","tnClass": "tn-3-2"},
        // "calves": {"label": "발목","tnClass": "tn-4-3"},
        "knees": {"label": "High","tnClass": "tn-3-3"}
      },
      "counters": {
        "open": {"label": "Open","tnClass": "tn-1-0"},
        "back": {"label": "Back","tnClass": "tn-1-1"},
        "full": {"label": "Full","tnClass": "tn-1-2"}
      }
    },
    overrideBodyPartGroupName: function (state, prop, val) {
      if (prop === 'shafts' && ['knees', 'ankles'].includes(val) && state['counters'] === 'open') {
        return 'shafts_' + val + '_counters_' + state['counters']
      }
      return null
    }
    // sanitizePropChange:  function(state, prop, sel) {
    //   // if shafts!=open, counter should be open or full
    //   if (prop === 'shafts' && sel !== 'open') {
    //     if (state['counters'] == 'back') {
    //       state['counters'] = 'full'
    //     }
    //     state['covers'] = 'full'
    //     state['counters'] = 'full'
    //   }
    //   if (prop === 'counters' && state['shafts'] !== 'open') {
    //     sel = 'full'
    //   }
    //   if (prop === 'covers') {
    //     // Don't allow changing cover when shaft is not open
    //     if (state['shafts'] != 'open') {
    //       sel = 'full'
    //     }
    //   }
    //   state[prop] = sel
    // }
  },
  "wsneakers": {
    "label": "Women Sneakers",
    "category": "wsneakers",
    "partList": ["toes", "neck", "sole", "cover"],
    "partListLabels" : ["Toes", "Neck", "Sole", "Cover"],
    "defaultVal": {"toes": "round", "neck": "full", "sole": "grip", "cover": "laceup"},
    "vfSvgSource": vf_svg_url('white/vf_wsneakers_core.svg'),
    "tnSprite": vf_svg_url('white/TN_Women_Sneakers_2.0.svg'),
    "presetList": {
      "Low top": {"toes": "flat", "neck": "low", "sole": "low", "cover": "laceup", "design": "solid"},
      "High top": {"toes": "flat", "neck": "high", "sole": "low", "cover": "laceup"},
      "Slip-on": {"toes": "flat", "neck": "low", "sole": "low", "cover": "plain"},
      "Chunky": {"toes": "pointy", "neck": "full", "sole": "chunky", "cover": "laceup"},
      "Running": {"toes": "pointy", "neck": "low", "sole": "grip", "cover": "laceup"},
      "Ornament": {"toes": "round", "neck": "low", "sole": "chunky", "cover": "ornament"},
    },
    "tn": {
      "toes": {
        "pointy": {"label": "Pointed","tnClass": "tn-0-0"},
        "round": {"label": "Round","tnClass": "tn-0-1"},
        "flat": {"label": "Flat","tnClass": "tn-0-2"}
      },
      "neck": {
        "low": {"label": "Low top","tnClass": "tn-1-0"},
        "full": {"label": "Mid top","tnClass": "tn-1-1"},
        "high": {"label": "High top","tnClass": "tn-1-2"}
      },
      "sole": {
        "low": {"label": "Flat","tnClass": "tn-2-0"},
        "grip": {"label": "Grip","tnClass": "tn-2-2"},
        "chunky": {"label": "Chunky","tnClass": "tn-2-1"},
      },
      "cover": {
        "plain": {"label": "Plain","tnClass": "tn-3-0"},
        "laceup": {"label": "Lace-up","tnClass": "tn-3-1"},
        "strap": {"label": "Strap","tnClass": "tn-3-2"},
        "ornament": {"label": "Ornament","tnClass": "tn-3-3"}
      },
    },
    "extraBodyGroups": ["shadow"],
    overrideBodyPartGroupName: function (state, prop, val) {
      if (prop === 'cover' && ['laceup', 'strap'].includes(val)) {
        return 'cover_' + val + '_neck_' + state['neck']
      }
      if (prop === 'sole') {
        return 'sole_' + val + '_toes_' + state['toes']
      }
      return null
    }
  },
  "wbag": {
    "label": "Women Bags",
    "category": "wbag",
    "partList" : ["size_h", "size_v", "handle", "closure"],
    "partListLabels" : ["Size", "Size", "Handle", "Closure"],
    "defaultVal" : {"direction": "tall", "size_h": "large", "size_v": "large", "size": "large", "handle": "medium", "closure": "flap"},
    "presetList": {
      "Shoulder": {"direction": "tall", "size_h": "large", "size_v": "large", "size": "large", "handle": "medium", "closure": "flap"},
      "Bucket":   {"direction": "tall", "size_h": "medium", "size_v": "medium", "size": "medium", "handle": "long", "closure": "string"},
      "Tote":     {"direction": "tall", "size_h": "large", "size_v": "large", "size": "large", "handle": "short", "closure": "none"},
      "Wrist":    {"direction": "wide", "size_h": "medium", "size_v": "medium", "size": "medium", "handle": "short", "closure": "plain"},
      "Big Bag":  {"direction": "wide", "size_h": "large", "size_v": "large", "size": "large", "handle": "short", "closure": "non"}
    },
    "vfSvgSource": vf_svg_url('usdemo/vf_wbag_core.svg'),
    "tnSprite": vf_svg_url('common/TN-Women-Bags.svg'),
    "tn": {
      "size_h" : {
        "small": {"label": "Small", "tnClass": "tn-1-0"},
        "medium": {"label": "Medium", "tnClass": "tn-1-1"},
        "large": {"label": "Large", "tnClass": "tn-1-2"}
      },
      "size_v" : {
        "small": {"label": "Small", "tnClass": "tn-0-0"},
        "medium": {"label": "Medium", "tnClass": "tn-0-1"},
        "large": {"label": "Large", "tnClass": "tn-0-2"}
      },
      "handle": {
        "none": {"label": "None", "tnClass": "tn-2-0"},
        "short": {"label": "Short", "tnClass": "tn-2-1"},
        "medium": {"label": "Medium", "tnClass": "tn-2-2"},
        "long": {"label": "Long", "tnClass": "tn-2-3"}
      },
      "closure": {
        "none": {"label": "None", "tnClass": "tn-3-0"},
        "flap": {"label": "Flap", "tnClass": "tn-3-1"},
        "zipup": {"label": "Zipup", "tnClass": "tn-3-2"},
        "string": {"label": "String", "tnClass": "tn-3-3"}
      }
    },
    sanitizePropChange:  function(state, prop, sel) {
      if (prop === 'size_h') {
        state['direction'] = 'wide'
        state['size'] = sel
        state['size_v'] = sel
      }
      if (prop === 'size_v') {
        state['direction'] = 'tall'
        state['size'] = sel
        state['size_h'] = sel
      }
      state[prop] = sel
    },
    overrideBodyPartGroupName: function (state, prop, val) {
      // console.log('overrideBodyPartGroupName', state, prop, val)
      if (prop === 'size_h' || prop === 'size_v') {
        if ((prop === 'size_h' && state['direction'] == 'wide') ||
            (prop === 'size_v' && state['direction'] == 'tall')) {
          return prop + '_' + val
        } else {
          return 'no_display_group'
        }
      }
      if (prop === 'handle' && val !== 'none') {
        return 'handle_' + val + '_for_' + state['size'] + '_' + state['direction']
      }
      if (prop === 'closure' && val !== 'none') {
        return 'closure_' + val + '_for_' + state['size'] + '_' + state['direction']
      }
      return null
    }
  },
  "wwallet": {
    "label": "Women Wallets",
    "category": "wwallet",
    "partList" : ["size", "closure"],
    "partListLabels" : ["Size", "Closure"],
    "defaultVal" : {"size": "tall", "closure": "envelope"},
    "vfSvgSource": vf_svg_url('white/vf_wwallet_core.svg'),
    "tnSprite": vf_svg_url('white/TN-Women-Wallets-2.0.svg'),
    "presetList": {
      "Small": {"size": "small", "closure": "zipper"},
      "Card": {"size": "small", "closure": "pocket"},
      "Long": {"size": "large", "closure": "envelope"},
      "Bi-fold": {"size": "medium", "closure": "zipper"},
    },
    "tn": {
      "size": {
        "small": {"label": "Small","tnClass": "tn-0-0"},
        "medium": {"label": "Medium","tnClass": "tn-0-1"},
        "tall": {"label": "Tall","tnClass": "tn-0-2"},
        "large": {"label": "Large","tnClass": "tn-0-3"}
      },
      "closure": {
        "envelope": {"label": "Flap","tnClass": "tn-1-0"},
        "zipper": {"label": "Zipper","tnClass": "tn-1-1"},
        "pocket": {"label": "Pocket","tnClass": "tn-1-2"},
        // "other": {"label": "기타","tnClass": "tn-1-3"}
      }
    },
    overrideBodyPartGroupName: function (state, prop, val) {
      if (prop === 'closure') {
        if (val === 'other') {
          return 'closure_other'
        }
        return 'closure_' + val + '_size_' + state['size']
      }
      return null
    }
  },
  "wearring": {
    "label": "Women Earrings",
    "category": "wearring",
    "partList": ["shape","size"],
    "partListLabels" : ["Shape", "Size"],
    "defaultVal": {"shape": "abstract", "size": "small"},
    "presetList": {
      "Statement": {"shape": "abstract","size": "large"},
      "Big hoop":  {"shape": "ring","size": "large"},
      "Drop":      {"shape": "dropdown","size": "small"},
      "Linear":    {"shape": "dropdown","size": "large"},
      "Pearl":     {"shape": "abstract","size": "small", "material": "pearl"},
      "Playful":   {"shape": "specific","size": "large"}
    },
    "vfSvgSource": vf_svg_url('usdemo/vf_wearring_core.svg'),
    "tnSprite": vf_svg_url('common/TN-Women-Earrings.svg'),
    "tn": {
      "size": {
        "small": {"label": "small","tnClass": "tn-2-0"},
        "medium": {"label": "medium","tnClass": "tn-2-1"},
        "large": {"label": "large","tnClass": "tn-2-2"}
      },
      "shape": {
        "abstract": {"label": "Abstract","tnClass": "tn-0-0"},
        "specific": {"label": "Specific","tnClass": "tn-0-1"},
        "ring": {"label": "Ring","tnClass": "tn-0-2"},
        "dropdown": {"label": "Dropdown","tnClass": "tn-0-3"}
      }
    },
    overrideBodyPartGroupName (state, prop, val) {
      if (prop === 'shape') {
        return 'shape_'+ val + '_size_' + state['size']
      }
      if (prop === 'size') {
        return 'no_display_group'
      }
      return null
    }
  },
  "mtshirts": {
    "label": "Men T-Shirts",
    "category": "mtshirts",
    "partList": ["neckline","sleeves","waist"],
    "partListLabels" : ["Neckline", "Sleeves", "Waist"],
    "defaultVal": {"neckline": "flat", "sleeves": "long", "waist": "wide"},
    "presetList": {
      "Crewneck": {"neckline": "flat", "sleeves": "short", "waist": "straight"},
      "Polo": {"neckline": "collar", "sleeves": "short", "waist": "straight"},
      "Hoodie": {"neckline": "hood", "sleeves": "long", "waist": "straight"},
      "Pullover": {"neckline": "high", "sleeves": "long", "waist": "tight"},
      "Tank": {"neckline": "flat", "sleeves": "sleeveless", "waist": "straight"}
    },
    "vfSvgSource": vf_svg_url('white/vf_mtshirts_core.svg'),
    "tnSprite": vf_svg_url('white/TN-Men-Tshirts-v2.0.svg'),
    "tn": {
      "waist": {
        "tight": {"label": "Fitted","tnClass": "tn-2-0"},
        "straight": {"label": "Regular","tnClass": "tn-2-1"},
        "wide": {"label": "Loose","tnClass": "tn-2-2"}
      },
      "sleeves": {
        "sleeveless": {"label": "Sleeveless","tnClass": "tn-1-0"},
        "short": {"label": "Short","tnClass": "tn-1-1"},
        "long": {"label": "Long","tnClass": "tn-1-2"}
      },
      "neckline": {
        "flat": {"label": "Round","tnClass": "tn-0-0"}, // =flat/round
        "collar": {"label": "Collar","tnClass": "tn-0-1"},
        "hood": {"label": "Hoodie","tnClass": "tn-0-2"},
        "high": {"label": "High","tnClass": "tn-0-3"}
      }
    }
  },
  "mshirts": {
    "label": "Men Shirts",
    "category": "mshirts",
    "partList": ["neckline","waist","sleeves"],
    "partListLabels" : ["Neckline", "Waist", "Sleeves"],
    "defaultVal": {"neckline": "short","sleeves": "long", "waist": "wide"},
    "presetList": {
      "Basic": {"neckline": "short",         "sleeves": "long", "waist": "tight", "design": "solid"},
      "Casual": {"neckline": "short",        "sleeves": "short","waist": "wide"},
      "Dressy": {"neckline": "large", "sleeves": "long", "waist": "tight"},
      "Henley": {"neckline": "henley",       "sleeves": "long", "waist": "tight"},
      "Hawaiian": {"neckline": "camp",      "sleeves": "short","waist": "wide"}
    },
    "vfSvgSource": vf_svg_url('white/vf_mshirts_core.svg'),
    "tnSprite": vf_svg_url('white/TN-Men-Shirts-v2.0.svg'),
    "tn": {
      "waist": {
        "tight": {"label": "Fitted","tnClass": "tn-1-0"},
        // "straight": {"label": "Regular","tnClass": "tn-1-0"},
        "wide": {"label": "Loose","tnClass": "tn-1-1"}
      },
      "sleeves": {
        "short": {"label": "Short","tnClass": "tn-2-0"},
        "long": {"label": "Long","tnClass": "tn-2-1"}
      },
      "neckline": {
        "short": {"label": "Regular","tnClass": "tn-0-0"},
        "large": {"label": "Wide","tnClass": "tn-0-1"},
        "henley": {"label": "Henley","tnClass": "tn-0-2"},
        "camp": {"label": "Camp Collar","tnClass": "tn-0-3"}
      }
    },
    overrideBodyPartGroupName (state, prop, val) {
      if (prop === 'length') {
        return 'length_' + val + '_waist_' + state['waist']
      }
      return null
    }
  },
  "mouter": {
    "label": "Men Outerwears",
    "category": "mouter",
    "partList": ["neckline", "waist", "sleeves", "length"],
    "partListLabels" : ["Neckline", "Waist", "Sleeves", "Length"],
    "defaultVal": {"neckline": "low", "sleeves": "long","waist": "wide", "length": "long"},
    "presetList": {
      "Blazer":  {"neckline": "low",   "sleeves": "long",      "waist": "tight","length": "short", "material": "cotton,wool"},
      "Trucker": {"neckline": "collar","sleeves": "long",      "waist": "tight", "length": "short"},
      "Pullover":{"neckline": "high",  "sleeves": "long",      "waist": "tight","length": "short", "material": "fleece"},
      "Coat":    {"neckline": "collar","sleeves": "long",      "waist": "tight","length": "long"},
      "Hood":    {"neckline": "hood",  "sleeves": "long",      "waist": "wide","length": "short", "material": "cotton"},
      "Vest":    {"neckline": "high",  "sleeves": "sleeveless","waist": "tight","length": "short"}
    },
    "vfSvgSource": vf_svg_url('white/vf_mouter_core.svg'),
    "tnSprite": vf_svg_url('white/TN-Men-Outer-v2.0.svg'),
    "tn": {
      "length": {
        "short": {"label": "Regular","tnClass": "tn-3-0"},
        "long": {"label": "Long","tnClass": "tn-3-1"}
      },
      "waist": {
        "tight": {"label": "Fitted","tnClass": "tn-1-0"}, // tight + straight
        // "straight": {"label": "Regular","tnClass": "tn-1-0"},
        "wide": {"label": "Loose","tnClass": "tn-1-1"}
      },
      "neckline": {
        "low": {"label": "Low","tnClass": "tn-0-0"}, // =notch
        "flat": {"label": "Crew","tnClass": "tn-0-1"},
        "collar": {"label": "Collar","tnClass": "tn-0-2"},// =bal
        "hood": {"label": "Hoodie","tnClass": "tn-0-3"},
        "high": {"label": "High","tnClass": "tn-0-4"}
      },
      "sleeves": {
        "sleeveless": {"label": "Sleeveless","tnClass": "tn-2-0"},
        "long": {"label": "Long","tnClass": "tn-2-1"}
      }
    },
    overrideBodyPartGroupName (state, prop, val) {
      if (prop === 'length') {
        return 'length_' + val + '_waist_' + state['waist']
      }
      if (prop === 'sleeves' && val === 'sleeveless') {
        return 'sleeves_sleeveless_waist_' + state['waist']
      }
      if (prop === 'waist') {
        return 'no_display_group'
      }
      return null
    }
  },
  "mknit": {
    "label": "Men Knits",
    "category": "mknit",
    "partList": ["neckline","cardigan","sleeves","waist"],
    "partListLabels" : ["Neckline", "Cardigan/Sweater", "Sleeves", "Waist"],
    "defaultVal": {"neckline": "flat", "sleeves": "long", "waist": "tight","cardigan": "sweater"},
    "presetList": {
      "V-neck":   {"neckline": "low",   "sleeves": "long",      "waist": "tight", "cardigan": "sweater", "design": "solid"},
      "Pullover": {"neckline": "high",  "sleeves": "long",      "waist": "tight", "cardigan": "cardigan"},
      "Collared":     {"neckline": "collar","sleeves": "long",      "waist": "tight", "cardigan": "sweater", "design": "solid"},
      "Cardigan": {"neckline": "low",   "sleeves": "long",      "waist": "wide","cardigan": "cardigan"},
      "High-neck":{"neckline": "high",  "sleeves": "long",      "waist": "wide","cardigan": "sweater"},
      "Vest":     {"neckline": "low",   "sleeves": "sleeveless","waist": "tight","cardigan": "sweater"}
    },
    "vfSvgSource": vf_svg_url('white/vf_mknit_core.svg'),
    "tnSprite": vf_svg_url('white/TN-Men-Knit-v2.0.svg'),
    "tn": {
      "neckline": {
        "low": {"label": "Low/Notch","tnClass": "tn-0-0"}, // =vneck
        "flat": {"label": "Round","tnClass": "tn-0-1"}, // =flat
        "collar": {"label": "Collar","tnClass": "tn-0-2"},
        "high": {"label": "High","tnClass": "tn-0-3"}
      },
      "waist": {
        "tight": {"label": "Fitted","tnClass": "tn-3-0"}, // tight + straight
        // "straight": {"label": "Regular","tnClass": "tn-3-0"},
        "wide": {"label": "Loose","tnClass": "tn-3-1"}
      },
      "sleeves": {
        "sleeveless": {"label": "Sleeveless", "tnClass": "tn-2-0"},
        "half": {"label": "Short","tnClass": "tn-2-1"},
        "long": {"label": "Long","tnClass": "tn-2-2"}
      },
      "cardigan": {
        "sweater": {"label": "Sweater","tnClass": "tn-1-0"},
        "cardigan": {"label": "Cardigan","tnClass": "tn-1-1"}
      }
    },
    overrideBodyPartGroupName (state, prop, val) {
      if (prop === 'neckline') {
        return 'neckline_' + val + '_' + state['cardigan']
      }
      if (prop === 'cardigan') {
        return 'no_display_group'
      }
      return null
    }
  },
  "mpants": {
    "label": "Men Pants",
    "category": "mpants",
    "partList" : ["tightness", "length", "ankle"],
    "partListLabels" : ["Tightness", "Length", "Ankle"],
    "defaultVal" : {"tightness": "wide", "length": "cropped", "ankle": "straight"},
    "presetList": {
      "Wide":    {"tightness": "wide", "length": "cropped", "ankle": "wide"},
      "Dress":    {"tightness": "wide", "length": "long", "ankle": "straight", "material": "wool"},
      "Straight": {"tightness": "straight", "length": "long", "ankle": "straight", "material": "denim"},
      "Skinny":   {"tightness": "tight", "length": "long", "ankle": "tight"},
      "Short":    {"tightness": "wide", "length": "short", "ankle": "wide"},
      "Jogger":   {"tightness": "wide", "length": "long", "ankle": "tight", "material": "cotton"}
    },
    "vfSvgSource": vf_svg_url('white/vf_mpants_core.svg'),
    "tnSprite": vf_svg_url('white/TN-Men-Pants-v2.0.svg'),
    "tn" : {
      "tightness": {
        "tight": { "label": "Fitted", "tnClass": "tn-0-0" },
        "straight": { "label": "Regular", "tnClass": "tn-0-1" },
        "wide": { "label": "Loose", "tnClass": "tn-0-2" }
      },
      "length": {
        "short": { "label": "Shorts", "tnClass": "tn-1-0" },
        "cropped": { "label": "Cropped", "tnClass": "tn-1-1" },
        "long": { "label": "Regular", "tnClass": "tn-1-2" } // =regular
      },
      "ankle": {
        "tight": { "label": "Tight", "tnClass": "tn-2-0" },
        "straight": { "label": "Regular", "tnClass": "tn-2-0" },
        "wide": { "label": "Loose", "tnClass": "tn-2-1" }
      }
    },
    "extraBodyGroups": ["zipper"],
    evaluateDisabledParts(filter) {
      const disabled = [];
      if (filter['length'] === 'short') {
        disabled.push('ankle');
      }
      return disabled;
    },
    overrideBodyPartGroupName (state, prop, val) {
      if (prop === 'length') {
        return 'length_' + val + '_tightness_' + state['tightness']
      }
      if (prop === 'ankle') {
        if (state['length'] === 'short') {
          return 'no_display_group'
        } else {
          return 'ankle_' + val + '_length_' + state['length'] + '_tightness_' + state['tightness']
        }
      }
      if (prop === 'tightness') {
        return 'no_display_group'
      }
      return null
    }
  },
  "mdressshoes": {
    "label": "Men Dress Shoes",
    "category": "mdressshoes",
    "partList": ["toes", "neck", "sole", "shoestyle"],
    "partListLabels" : ["Toes", "Neck", "Sole", "Cover"],
    "defaultVal": {"toes": "apron","neck": "open","sole": "heel", "shoestyle": "plain"},
    "presetList": {
      "Classic": {"toes": "round",  "neck": "open", "sole": "heel", "shoestyle": "oxford", "material": "leather"},
      "Loafer":  {"toes": "pointy", "neck": "open", "sole": "heel", "shoestyle": "plain",  "material": "leather"},
      "Tassel":  {"toes": "pointy", "neck": "open", "sole": "heel", "shoestyle": "tassel", "material": "leather"},
      "Chelsea": {"toes": "pointy", "neck": "ankle","sole": "heel", "shoestyle": "plain",  "material": "leather"},
      "Boots":   {"toes": "round",  "neck": "high", "sole": "chunky", "shoestyle": "oxford"},
    },
    "vfSvgSource": vf_svg_url('white/vf_mdressshoes_core.svg'),
    "tnSprite": vf_svg_url('white/TN-Men-Shoes-v2.0.svg'),
    "tn": {
      "toes": {
        "pointy": {"label": "Pointed","tnClass": "tn-0-0"},
        "round": {"label": "Round","tnClass": "tn-0-1"},
        "apron": {"label": "Apron","tnClass": "tn-0-2"},
        // "open": {"label": "Open","tnClass": "tn-0-2"}
      },
      "neck": {
        "open": {"label": "Regular", "tnClass": "tn-1-0"},
        "ankle": {"label": "Ankle","tnClass": "tn-1-1"},
        "high": {"label": "High","tnClass": "tn-1-2"},
      },
      "sole": {
        "heel": {"label": "Heel","tnClass": "tn-2-0"},
        "solid": {"label": "Flat","tnClass": "tn-2-1"},
        "chunky": {"label": "Grip","tnClass": "tn-2-2"}
      },
      "shoestyle": {
        "plain": {"label": "Plain","tnClass": "tn-3-0"},
        "tassel": {"label": "Tassel/Horsebit","tnClass": "tn-3-1"},
        "oxford": {"label": "Laceup","tnClass": "tn-3-2"},
        // "apron": {"label": "Apron","tnClass": "tn-3-3"}
      }
    },
    "extraBodyGroups": ["shadow"],
    sanitizePropChange (state, prop, sel) {
      if (prop === 'neck' && sel !== 'open') {
        if (state['toes'] == "open") {
          state['toes'] = "round"
        }
      }
      if (prop === "toes" && sel === "open") {
        if (state['neck'] != 'open') {
          sel = "pointy"
        }
      }
      state[prop] = sel
    },
    overrideBodyPartGroupName (state, prop, val) {
      if (prop === 'sole') {
        return 'sole_' + val + '_toe_' + state['toes']
      }
      if (prop === "shoestyle" && val === "oxford") {
        return 'shoestyle_oxford_neck_' + state['neck']
      }
      if (prop === 'neck' && val === 'open' && state['shoestyle'] === 'oxford') {
        return 'neck_open_oxford'
      }
      return null
    }
  },
  "msneakers": {
    "label": "Men Sneakers",
    "category": "msneakers",
    "partList": ["toes", "neck", "sole", "cover"],
    "partListLabels" : ["Toes", "Neck", "Sole", "Cover"],
    "defaultVal": {"toes": "round", "neck": "low", "sole": "grip", "cover": "plain"},
    "vfSvgSource": vf_svg_url('white/vf_msneakers_core.svg'),
    "tnSprite": vf_svg_url('white/TN-Men-Sneakers-2.0.svg'),
    "presetList": {
      "Low top": {"toes": "flat", "neck": "low", "sole": "low", "cover": "laceup", "design": "solid"},
      "High top": {"toes": "flat", "neck": "high", "sole": "low", "cover": "laceup"},
      "Slip-on": {"toes": "flat", "neck": "low", "sole": "low", "cover": "plain"},
      "Chunky": {"toes": "pointy", "neck": "full", "sole": "chunky", "cover": "laceup"},
      "Running": {"toes": "round", "neck": "low", "sole": "grip", "cover": "laceup"},
      "Sock": {"toes": "round", "neck": "high", "sole": "low", "cover": "plain"},
    },
    "extraBodyGroups": ["shadow"],
    "tn": {
      "toes": {
        "pointy": {"label": "Pointed","tnClass": "tn-0-0"},
        "round": {"label": "Round","tnClass": "tn-0-1"},
        "flat": {"label": "Flat","tnClass": "tn-0-2"}
      },
      "neck": {
        "low": {"label": "Low top","tnClass": "tn-1-0"},
        "full": {"label": "Mid top","tnClass": "tn-1-1"},
        "high": {"label": "High top","tnClass": "tn-1-2"}
      },
      "sole": {
        "low": {"label": "Flat","tnClass": "tn-2-0"},
        "grip": {"label": "Grip","tnClass": "tn-2-2"},
        "chunky": {"label": "Chunky","tnClass": "tn-2-1"},
      },
      "cover": {
        "plain": {"label": "Plain","tnClass": "tn-3-0"},
        "laceup": {"label": "Laceup","tnClass": "tn-3-1"}
      }
    },
    overrideBodyPartGroupName (state, prop, val) {
      if (prop === 'sole') {
        return 'sole_' + val + '_toes_' + state['toes']
      }
      if (prop === 'cover' && val == 'laceup') {
        return 'cover_laceup_neck_' + state['neck']
      }
      return null
    }
  },
  "mbag": {
    "label": "Men Bags",
    "category": "mbag",
    "partList" : ["size_h", "size_v", "handle", "style"],
    "partListLabels" : ["Size", "Size", "Handle", "Style"],
    "defaultVal" : {"direction": "tall", "size": "large", "size_h": "large", "size_v": "large", "handle": "medium", "style": "flap"},
    "presetList": {
      "Backpack": {"direction": "tall", "size": "large", "size_h": "large", "size_v": "large", "handle": "backpack", "style": "flap"},
      "Tote":     {"direction": "tall", "size": "medium", "size_h": "medium", "size_v": "medium", "handle": "medium", "style": "none"},
      "Hip Pack": {"direction": "wide", "size": "small", "size_h": "small", "size_v": "small", "handle": "none", "style": "zipper"},
      "Cross Body":{"direction": "tall", "size": "small", "size_h": "small", "size_v": "small", "handle": "long", "style": "zipper"},
      "Messenger":{"direction": "wide", "size": "large", "size_h": "large", "size_v": "large", "handle": "long", "style": "flap"}
    },
    "vfSvgSource": vf_svg_url('usdemo/vf_mbag_core.svg'),
    "tnSprite": vf_svg_url('common/TN-Men-Bags.svg'),
    "tn": {
      "size_h" : {
        "small": {"label": "Small", "tnClass": "tn-1-0"},
        "medium": {"label": "Medium", "tnClass": "tn-1-1"},
        "large": {"label": "Large", "tnClass": "tn-1-2"}
      },
      "size_v" : {
        "small": {"label": "Small", "tnClass": "tn-2-0"},
        "medium": {"label": "Medium", "tnClass": "tn-2-1"},
        "large": {"label": "Large", "tnClass": "tn-2-2"},
      },
      "handle": {
        "none": {"label": "None", "tnClass": "tn-3-0"},
        "medium": {"label": "Medium", "tnClass": "tn-3-2"},
        "long": {"label": "Strap", "tnClass": "tn-3-3"},
        "backpack": {"label": "Backpack", "tnClass": "tn-3-4"},
      },
      "style": {
        "none": {"label": "Open", "tnClass": "tn-4-0"},
        "flap": {"label": "Flap", "tnClass": "tn-4-1"},
        "zipper": {"label": "Zipper", "tnClass": "tn-4-2"}
      }
    },
    sanitizePropChange: function(state, prop, sel) {
      if (prop === 'size_h') {
        if (state['direction'] === 'tall')
        state['direction'] = 'wide'
        state['size'] = sel
        state['size_v'] = sel
      }
      if (prop === 'size_v') {
        state['direction'] = 'tall'
        state['size'] = sel
        state['size_h'] = sel
      }
      // backpack handle + horizontal bag combo is not allowed
      if (prop === 'handle' && sel === 'backpack') {
        state['direction'] = 'tall'
      }
      if (prop === 'size_h' && state['handle'] === 'backpack') {
        state['handle'] = 'medium'
      }
      state[prop] = sel
    },
    overrideBodyPartGroupName: function (state, prop, val) {
      // console.log('overrideBodyPartGroupName', state, prop, val)
      if (prop === 'size_h' || prop === 'size_v') {
        if ((prop === 'size_h' && state['direction'] == 'wide') ||
            (prop === 'size_v' && state['direction'] == 'tall')) {
          return prop + '_' + val
        } else {
          return 'no_display_group'
        }
      }
      if (prop === 'handle' && val !== 'none') {
        return 'handle_' + val + '_for_' + state['size'] + '_' + state['direction']
      }
      if (prop === 'style' && val !== 'none') {
        return 'style_' + val + '_for_' + state['size'] + '_' + state['direction']
      }
      return null
    }
  },
  "mwallet": {
    "label": "Men Wallets",
    "category": "mwallet",
    "partList" : ["size", "style"],
    "partListLabels" : ["Size", "Style"],
    "defaultVal" : {"size": "tall", "style": "folder"},
    "presetList": {
      "Fold": {"size": "half", "style": "folder"},
      "Long": {"size": "long", "style": "zipper"},
      "Card": {"size": "half", "style": "pocket"},
      "Fabric": {"size": "half", "style": "folder", "material": "fabric"}
    },
    "vfSvgSource": vf_svg_url('white/vf_mwallet_core.svg'),
    "tnSprite": vf_svg_url('white/TN-Men-Wallets-2.0.svg'),
    "tn": {
      "size": {
        "half": {"label": "Half","tnClass": "tn-0-0"},
        "tall": {"label": "Tall","tnClass": "tn-0-1"},
        "long": {"label": "Long","tnClass": "tn-0-2"},
        // "money_clip": {"label": "머니클립","tnClass": "tn-0-3"},
        // "other": {"label": "기타","tnClass": "tn-0-4"}
      },
      "style": {
        "plain": {"label": "Plain","tnClass": "tn-1-0"},
        "zipper": {"label": "Zipper","tnClass": "tn-1-1"},
        "folder": {"label": "Folder","tnClass": "tn-1-2"},
        "pocket": {"label": "Pocket","tnClass": "tn-1-3"}
      }
    },
    overrideBodyPartGroupName (state, prop, val) {
      let size = state['size']
      if (prop === 'style' && (val !== 'plain')) {
        if (size === 'money_clip' || size == 'other') {
          return 'style_plain' // No style for these sizes
        } else {
          return 'style_' + val + '_size_' + state['size']
        }
      }
      return null
    }
  },
};
