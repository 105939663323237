import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import styles from './TreeCategoriesMenu.module.scss';
import classNames from 'classnames';
import { findLabelByLng } from '@yesplz/core';
import buildTree from '@yesplz/core/src/buildTree';

function TreeItems({ items, isPreviewPage, onItemClick }) {
  return (
    <ul>
      {items.map(({ label, isPublished, categoryId, children }, index) => (
        <li key={categoryId}>
          <span>
            <NavLink onClick={onItemClick} to={`/visual-filter${isPreviewPage ? '' : '-editor'}/${encodeURIComponent(categoryId)}`}>{findLabelByLng(label)}</NavLink>
            {isPublished ? <CloudDoneIcon color="primary" /> : null}
          </span>
          {children && children.length && <TreeItems isPreviewPage={isPreviewPage} items={children} onItemClick={onItemClick} />}
        </li>
      ))}
    </ul>
  );
}

export default function CategoriesMenu({ categories }) {
  const tree = buildTree(Object.values(categories));
  const [tab, setTab] = useState(tree[0].categoryId);
  const [isClicked, setClicked] = useState();
  const location = useLocation();
  const isPreviewPage = location.pathname.includes('/visual-filter') && !location.pathname.includes('/visual-filter-editor');

  useEffect(() => {
    if (isClicked) setTimeout(() => setClicked(false), 0);
  }, [isClicked]);

  return (
    <div className={classNames(styles['categories'], {
      [styles['clicked']]: isClicked,
    })}>
      <span>Categories <i><ArrowDropDownIcon /></i></span>
      <div className={styles['categories-dropdown']}>
        <div className={styles['tabs-list']}>
          <ul>
            {tree.map(({ label, categoryId }) => (
              <li
                key={categoryId}
                className={classNames({ [styles['is-active']]: tab === categoryId })}
                onClick={() => setTab(categoryId)}
              >{findLabelByLng(label)}</li>
            ))}
          </ul>
        </div>
        {tree.map(({ categoryId, children }) => (
          <div
            key={categoryId}
            className={classNames(styles['tab'], { [styles['is-active']]: tab === categoryId})}
          >
            <TreeItems items={children || []} isPreviewPage={isPreviewPage} onItemClick={() => setClicked(true)} />
          </div>
        ))}
      </div>
    </div>
  );
}
