import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import cloneDeep from 'lodash/cloneDeep';
import { PRODUCT_COUNT_PER_PAGE } from './consts';

const LRU = require('lru-cache');

const cacheOptions = {
  max: 500,
  maxSize: 5000,
  sizeCalculation: () => 1,
  ttl: 1000 * 60 * 5,
  allowStale: false,
};

const cache = new LRU(cacheOptions);

function joinArrayParams(rawParams) {
  return Object.entries(rawParams).reduce((params, [key, value]) => ({
    ...params,
    [key]: Array.isArray(value) ? value.join(',') : value,
  }), {});
}

const client = {
  isAdmin: false,
  baseURL: '',
  searchPath: '/dmf/',
  aliases: {},
  setIsAdmin: (isAdmin) => {
    client.isAdmin = !!isAdmin;
  },
  setBaseURL: (url) => {
    client.baseURL = url;
  },
  setSearchPath: (path) => {
    client.searchPath = path;
  },
  setAliases: (aliases) => {
    client.aliases = aliases;
  },
  replaceAliases: (baseParams) => {
    const params = cloneDeep(baseParams);
    Object.entries(client.aliases).map(([key, alias]) => {
      if (params[key] !== undefined && params[key] !== null) {
        params[alias] = params[key];
        delete params[key];
      }
    });

    return params;
  },
  removeEmptyParams: (baseParams) => {
    return baseParams;
    // return pickBy(baseParams, (value) => value !== undefined && value !== null && value !== '');
  },
  textBrands: async ({ query, count = 3 }, additionalParams = {}) => {
    const response = await axios.get(`${client.baseURL}/brandsuggestion`, {
      params: {
        ...(additionalParams || {}),
        query,
        count,
      },
    });
    return camelcaseKeys(response.data.brands || response.data, { deep: true });
  },
  textAutocomplete: async ({ query, topCategory = null, sale = false, count = 5 }, additionalParams = {}) => {
    const response = await axios.get(`${client.baseURL}/autocomplete`, {
      params: {
        ...(additionalParams || {}),
        query,
        top_category: topCategory,
        count,
        sale: sale ? 'saleonly' : 'all',
      },
    });
    return camelcaseKeys(response.data.results || response.data, { deep: true });
  },
  textPopularQueries: async ({ topCategory = null, sale = false, count = 5 }, additionalParams = {}) => {
    const response = await axios.get(`${client.baseURL}/popularsuggestion`, {
      params: {
        ...(additionalParams || {}),
        top_category: topCategory,
        count,
        sale: sale ? 'saleonly' : 'all',
      },
    });
    return camelcaseKeys(response.data.results || response.data, { deep: true });
  },
  textSearch: async ({ mode = 'exact only', limit = 4, offset = 0, ...params }, additionalParams = {}) => {
    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        params[key] = value.join(key === 'categories' ? '*' : ',');
      }
    });

    if (params.topCategory) {
      params.top_category = params.topCategory;
      delete params.topCategory;
    }

    const response = await axios.get(`${client.baseURL}/textsearch`, {
      params: client.replaceAliases({
        mode,
        limit,
        offset: offset < 0 ? 0 : offset,
        ...params,
        ...(additionalParams || {}),
      }),
      transformRequest: [function (data, headers) {
        return data;
      }],
    });
    return camelcaseKeys(response.data || response.data, { deep: true });
  },
  fetchFilters: async (type, params, additionalParams = {}) => {
    const response = await axios.get(`${client.baseURL}/filters/${type}/`, {
      params: client.replaceAliases({
        ...joinArrayParams(params || {}),
        ...(additionalParams || {}),
      }),
    });
    return camelcaseKeys(response.data, { deep: true });
  },
  priceFilters: (params, additionalParams = {}) => client.fetchFilters('price', params, additionalParams),
  sizeFilters: async (params, additionalParams = {}) => {
    const data = await client.fetchFilters('sizes', params, additionalParams);
    return data.sizes;
  },
  categoryFilters: async (params, additionalParams = {}) => {
    const data = await client.fetchFilters('categories', params, additionalParams);
    return data.categories;
  },
  sizesByCategory: async (categoryId, additionalParams = {}) => {
    const response = await axios.get(`${client.baseURL}/filters/sizes/by_category/${categoryId}`, {
      params: {
        ...(additionalParams || {}),
        format: 'json',
      },
    });
    return camelcaseKeys(response.data[categoryId] || response.data, { deep: true });
  },
  materialsByCategory: async (categoryId, additionalParams = {}) => {
    const response = await axios.get(`${client.baseURL}/filters/materials/by_category/${categoryId}`, {
      params: {
        ...(additionalParams || {}),
        format: 'json',
      },
    });
    return camelcaseKeys(response.data[categoryId] || response.data, { deep: true });
  },
  brandsByCategories: async (categoryId, additionalParams = {}) => {
    const response = await axios.get(`${client.baseURL}/filters/brands/by_category/${categoryId}`, {
      params: {
        ...(additionalParams || {}),
        format: 'json',
      },
    });
    return camelcaseKeys(response.data[categoryId] || response.data, { deep: true });
  },
  pricesByCategories: async (categoryId, additionalParams) => {
    const response = await axios.get(`${client.baseURL}/filters/price/by_category/${categoryId}`, {
      params: {
        ...(additionalParams || {}),
        format: 'json',
      },
    });
    return camelcaseKeys(response.data[categoryId] || response.data, { deep: true });
  },
  product: async (productId) => {
    const response = await axios.get(`${client.baseURL}/products/${productId}`, {
      headers: {
        authorization: 'Token 9221e1935dcdb66609310cefa39e96d0b67794d2',
      },
    });
    return camelcaseKeys(response.data, { deep: true });
  },
  yml: async (productId, offset = 0, limit = 20) => {
    const response = await axios.get(`${client.baseURL}/recommends/yml`, {
      params: {
        productId,
        offset: offset < 0 ? 0 : offset,
        limit,
        selected_product_id: productId,
      },
    });
    return camelcaseKeys(response.data, { deep: true });
  },
  cf: async (productId, offset = 0, limit = 20) => {
    const response = await axios.get(`${client.baseURL}/recommends/cf`, {
      params: {
        productId,
        offset: offset < 0 ? 0 : offset,
        limit,
        selected_product_id: productId,
      },
    });
    return camelcaseKeys(response.data, { deep: true });
  },
  async search(
    category,
    clientCategory,
    rawParams = {},
    sort,
    offset,
    limit = PRODUCT_COUNT_PER_PAGE,
    additionalParams,
    requestConfig,
    occasion,
    mood,
    categorySlice,
    isPreview = false,
  ) {
    const params = Object.entries(rawParams).reduce((params, [key, value]) => ({
      ...params,
      [key]: Array.isArray(value) ? value.join(key === 'subcategory' ? '*' : ',') : value,
    }), {});

    if (params.brands) {
      params.brands_filter = params.brands;
      delete params.brands;
    }
    if (params.price) {
      params.price_filter = params.price;
      delete params.price;
    }
    if (occasion) {
      params.occasion = Array.isArray(occasion) ? occasion.join(',') : occasion;
      // params.occasion = `${params.vibe ? params.vibe + '*' : ''}${occasion}`;
    }
    if (mood) {
      params.mood = mood;
    }
    if (params.subcategory && params.subcategory.length) {
      clientCategory = params.subcategory;
    }
    delete params.subcategory;

    if (categorySlice) {
      clientCategory = `${clientCategory}*${categorySlice}`;
    }
    if (params.materialText) {
      params['material_text'] = params.materialText;
      delete params.materialText;
    }
    if (params.shippingOption) {
      params['shipping_option'] = params.shippingOption;
      delete params.shippingOption;
    }
    if (params.newArrivals) {
      params['new_arrival'] = params.newArrivals;
      delete params.newArrivals;
    }
    params.preview = isPreview ? 'true' : 'false';

    try {
      const requestOptions = {
        params: client.replaceAliases(client.removeEmptyParams({
          category,
          clientCategory: clientCategory?.toLowerCase() === 'all' ? '' : clientCategory,
          limit: limit,
          offset: offset < 0 ? 0 : offset,
          sort,
          ...params,
          ...(client.isAdmin ? { admin: 1 } : {}),
          ...(additionalParams || {}),
          format: 'json',
        })),
        ...requestConfig,
      };

      const cacheKey = JSON.stringify({
        baseURL: client.baseURL,
        ...requestOptions,
      });
      let data = cache.get(cacheKey);

      if (!data) {
        const response = await axios.get(`${client.baseURL}${client.searchPath}`, requestOptions);
        data = camelcaseKeys(response.data, { deep: true });
        cache.set(cacheKey, data);
      }

      return data;
    } catch (e) {
      console.log('Error!', e);
      throw e;
    }
  }
}

export default client;
