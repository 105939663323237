import React from 'react';
import VisualFilterPage from 'modules/visual-filter-page';
import {
  categories as yesplzFashionCategories,
  designFilterParams as yesplzFashionDesignFilterParams,
  materialFilterParams as yesplzFashionMaterialFilterParams,
} from '@yesplz/shopify-widget/src/stores/yesplz-fashion/config';
import {
  categories as upSwagFashionCategories,
  designFilterParams as upSwagFashionDesignFilterParams,
  materialFilterParams as upSwagFashionMaterialFilterParams,
} from '@yesplz/shopify-widget/src/stores/up-swag/config';
import {
  categories as ayahshopFashionCategories,
  designFilterParams as ayahshopFashionDesignFilterParams,
  materialFilterParams as ayahshopFashionMaterialFilterParams,
} from '@yesplz/shopify-widget/src/stores/ayahshop/config';
import {
  categories as markatiMeFashionCategories,
  patternsFilterParams as markatiMeFashionDesignFilterParams,
  materialsFilterParams as markatiMeFashionMaterialFilterParams,
} from '@yesplz/shopify-widget/src/stores/markati-me/config';

const shopsConfigurations = {
  'yesplz-fashion': {
    categories: yesplzFashionCategories,
    designFilterParams: yesplzFashionDesignFilterParams,
    materialFilterParams: yesplzFashionMaterialFilterParams,
  },
  'up-swag': {
    categories: upSwagFashionCategories,
    designFilterParams: upSwagFashionDesignFilterParams,
    materialFilterParams: upSwagFashionMaterialFilterParams,
  },
  'ayahshop-jo': {
    categories: ayahshopFashionCategories,
    designFilterParams: ayahshopFashionDesignFilterParams,
    materialFilterParams: ayahshopFashionMaterialFilterParams,
  },
  'markati-me': {
    categories: markatiMeFashionCategories,
    designFilterParams: markatiMeFashionDesignFilterParams,
    materialFilterParams: markatiMeFashionMaterialFilterParams,
  },
};

export default function VisualFilter({ match }) {
  const { shop } = match.params;
  return (
    <VisualFilterPage
      {...shopsConfigurations[shop]}
      searchAdditionalParams={{ shop }}
    />
  );
}
