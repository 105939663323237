import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HeaderMenu from 'components/HeaderMenu';
import { useHistory, useRouteMatch } from 'react-router';
import isEmpty from 'lodash/isEmpty';

import {
  categories as defaultCategories,
} from 'config/vf-config.js';
import classNames from 'classnames';
import iphone from '../../assets/images/iphone.svg';
import { useSelector } from 'react-redux';
import { fillCategoriesByParent } from '@yesplz/core';

function buildIframeUrl({ categoryId, isTooltipActive, sort, retailer, lng, serverType }) {
  return `/visual-filter-preview/${encodeURIComponent(categoryId)}?isTooltipActive=${isTooltipActive}${sort ? '&sort=' + sort : ''}${retailer ? '&retailer=' + retailer : ''}${lng ? '&lng=' + lng : ''}${serverType ? '&serverType=' + serverType : ''}&timestamp=${Date.now()}`;
}

function Page({
  retailer,
  configuration,
  extendedCategories = defaultCategories,
}) {
  const categories = Object.entries(fillCategoriesByParent(configuration)).reduce((categories, [categoryId, category]) => {
    categories[categoryId] = {
      ...extendedCategories[category.baseCategory],
      ...category,
    };
    return categories;
  }, {});

  const iframeRef = useRef();
  const classes = useStyles();
  const history = useHistory();
  const { params: routerParams } = useRouteMatch();
  const categoryId = routerParams.categoryId ? decodeURIComponent(routerParams.categoryId) : null;
  const [isMobile, setIsMobile] = useState(false);
  const [isTooltipActive, setIsTooltipActive] = useState(false);
  const [sort, setSort] = useState(null);
  const { lng, serverType } = useSelector(({ AdminAuth }) => AdminAuth);

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow.location.href = buildIframeUrl({ categoryId, isTooltipActive, sort, retailer, serverType });
    }
  }, []);

  useEffect(() => {
    if (!categoryId || !configuration[categoryId] || (configuration[categoryId] && !configuration[categoryId].isPublished)) {
      const lastUsedCategoryId = localStorage.getItem('lastCategoryId');
      const publishedCategories = Object.values(configuration).filter(({ isPublished }) => isPublished);
      const newCategoryId = (
        lastUsedCategoryId && configuration[lastUsedCategoryId] && configuration[lastUsedCategoryId].isPublished
          ? lastUsedCategoryId
          : publishedCategories.length
            ? publishedCategories[0].categoryId
            : ''
      );
      history.push(`/visual-filter/${encodeURIComponent(newCategoryId)}`);
    }
    else {
      localStorage.setItem('lastCategoryId', categoryId);
    }
  }, [categoryId]);

  useEffect(() => {
    const iframeUrl = buildIframeUrl({ categoryId, isTooltipActive, sort, retailer, lng, serverType });
    if (
      iframeRef.current
      // &&
      // iframeRef.current.contentWindow.location.href !== 'about:blank'
      &&
      iframeRef.current.contentWindow.location.href !== iframeUrl
    ) {
      iframeRef.current.contentWindow.location.href = iframeUrl;
    }
  }, [categoryId, isTooltipActive, sort, retailer, lng, serverType]);

  if (isEmpty(categories)) return (
    <div className={classes.root}>
      <h1>No active categories</h1>
    </div>
  );

  if (!configuration[categoryId]) return null;

  return (
    <div className={classes.root}>
      <HeaderMenu
        categories={categories}
        isTooltipActive={isTooltipActive}
        isMobile={isMobile}
        onTooltipClick={() => setIsTooltipActive(!isTooltipActive)}
        onPreviewClick={() => setIsMobile(!isMobile)}
      />
      <div className={classNames(
        classes.mainContainer,
        {
          [classes.isMobile]: isMobile,
        }
      )}>
        <iframe ref={iframeRef} />
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    height: "calc(100vh - 65px)",
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    padding: '10px 35px 20px 0',
    backgroundColor: '#ffffff',
    position: 'relative',
    borderBottom: '1px solid #eae9ea',
    display: 'none',
  },
  categoryContainer: {
    width: 230,
  },
  sortContainer: {
    flex: 1,
  },
  switchContainer: {
    '& .Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#009688',
    },
    '& .Mui-checked': {
      color: '#009688',
    }
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    flex: 1,

    '& iframe': {
      width: '100%',
    },
  },
  isMobile: {
    display: 'block',
    padding: '2rem',
    overflowY: 'auto',
    '& iframe': {
      display: 'block',
      margin: '0 auto',
      width: '400px',
      height: '812px',
      borderRadius: '4rem',
      overflow: 'hidden',
      // height: 'calc(100vh - 65px - 65px)',
      // boxShadow: '0px 4.27111px 21.3556px rgba(88, 89, 91, 0.2)',
      background: `url(${iphone}) center center no-repeat`,
      backgroundSize: '400px 812px',
      padding: '50px 20px 20px'
    },
  },
  resultController: {
    flex: 1,
    height: 'calc(100vh - 65px - 65px)',
    overflow: 'hidden',
    overflowY: 'scroll',
    padding: 15,
  },
  resultList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
  },
  product: {
    marginBottom: 25,
  },
});

export default Page;
