import upperFirst from 'lodash/upperFirst';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import uniq from 'lodash/uniq';
import { patternsFilterParams, materialsFilterParams } from '../extended-configuration';
import closeSvg from '@yesplz/core-web/assets/svg/close-icon-black.svg';
import tooltipCloseSvg from '@yesplz/core-web/assets/images/tooltip-close.svg';
import resetIconSvg from '@yesplz/core-models/assets/svg/reset-icon.svg';
import { findLabelByLng, formatCurrency } from '@yesplz/core';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';
import Hooks from '../modules/Hooks';
import { createPopper } from '@popperjs/core';

const { document } = window;

export const WIDGETS_MAP = {
  'treeMenu': 'TreeViewMenu',
  'categories': 'SimpleCategoriesList',
  'subcategories': 'SubcategoriesList',
  'presets': 'SimplePresetsList',
  'occasions': 'SimpleOccasionsList',
  'subtypes': 'SimpleSubtypesList',
  'vibes': 'SimpleVibesList',
  'moods': 'SimpleMoodsList',
  'patterns': 'DesignFilter',
  'colors': 'ColorFilter',
  'materials': 'MaterialFilter',
  'textMaterials': 'MaterialTextList',
  'price': 'PriceRangeFilter',
  'brand': 'BrandFilter',
  'size': 'SizeFilter',
  'discounts': 'DiscountsFilter',
  'shipping': 'ShippingOptionsFilter',
  'chips': 'Chips',
  'wash': 'WashFilter',
  'sort': 'SortBy',
  'newArrivals': 'NewArrivalsFilter',
  'dynamicRange': 'DynamicRangeFilter',
  'dynamic': 'DynamicList',
};

const ONBOARDING_LOCAL_STORAGE_KEY = 'yesplz-collapsible-onboarding-passed';

export class CollapsibleFilters extends Widget {
  defaultParams = {
    filters: ['categories', 'presets', 'occasions', 'style', 'patterns', 'colors', 'brand', 'price'],
    stickyHeaders: ['brand'],
    filtersOpenByDefault: ['style'],
    filtersOpenByDefaultMobile: ['style'],
    filterLabels: {
      'treeMenu': [
        { lng: 'en', label: 'Categories' },
        { lng: 'ko', label: '카테고리' },
      ],
      'categories': [
        { lng: 'en', label: 'Category' },
        { lng: 'ko', label: '카테고리' },
      ],
      'subcategories': [
        { lng: 'en', label: 'Sub Category' },
        { lng: 'ko', label: '카테고리' },
      ],
      'occasions': [
        { lng: 'en', label: 'Occasion' },
        { lng: 'ko', label: '기회' },
      ],
      'subtypes': [
        { lng: 'en', label: 'Subtype' },
        { lng: 'ko', label: '하위 유형' },
      ],
      'vibes': [
        { lng: 'en', label: 'Vibe' },
        { lng: 'ko', label: '바이브' },
      ],
      'moods': [
        { lng: 'en', label: 'Mood' },
        { lng: 'ko', label: '무드' },
      ],
      'style': [
        { lng: 'en', label: 'Style' },
        { lng: 'ko', label: '스타일' },
      ],
      'patterns': [
        { lng: 'en', label: 'Pattern & Feature' },
        { lng: 'ko', label: '패턴' },
      ],
      'presets': [
        { lng: 'en', label: 'Product Type' },
        { lng: 'ko', label: '상품 유형' },
      ],
      'colors': [
        { lng: 'en', label: 'Color' },
        { lng: 'ko', label: '색상' },
      ],
      'patterns': [
        { lng: 'en', label: 'Pattern' },
        { lng: 'ko', label: '패턴' },
      ],
      'brand': [
        { lng: 'en', label: 'Brand' },
        { lng: 'ko', label: '브랜드' },
      ],
      'materials': [
        { lng: 'en', label: 'Material' },
        { lng: 'ko', label: '소재' },
      ],
      'textMaterials': [
        { lng: 'en', label: 'Material' },
        { lng: 'ko', label: '소재' },
      ],
      'price': [
        { lng: 'en', label: 'Price' },
        { lng: 'ko', label: '가격' },
      ],
      'size': [
        { lng: 'en', label: 'Size' },
        { lng: 'ko', label: '사이즈' },
      ],
      'wash': [
        { lng: 'en', label: 'Wash Type' },
        { lng: 'ko', label: '워쉬' },
      ],
      'sort': [
        { lng: 'en', label: 'Sort' },
        { lng: 'ko', label: '정렬' },
      ],
      'discounts': [
        { lng: 'en', label: 'Discount' },
        { lng: 'ko', label: 'Discount' },
      ],
      'shipping': [
        { lng: 'en', label: 'Shipping Options' },
        { lng: 'ko', label: 'Shipping Options' },
      ],
      'newArrivals': [
        { lng: 'en', label: 'New Arrivals' },
        { lng: 'ko', label: 'New Arrivals' },
      ],
    },
    usePresetFilter: false,
    useHeader: true,
    useFooter: true,
    isFoldable: true,
    isOpenInitialy: false,
    // stylesAsPrefix: false,
    categoriesFilterParams: {},
    treeMenuFilterParams: {},
    visualFilterParams: {
      svgViewBox: [0, 0, 362, 254],
      svgViewBoxMobile: [0, 0, 362, 254],
      showTooltipsToggler: false,
      presetNavigation: false,
      showParamTags: true,
      tagHighlightColor: '#2F30EB',
      useOnboarding: true,
    },
    styleFilterParams: {},
    patternsFilterParams: {
      ...patternsFilterParams,
      showReset: true,
    },
    colorsFilterParams: {
      resetImage: resetIconSvg,
      displayLabels: true,
    },
    materialsFilterParams: {
      ...materialsFilterParams,
      showReset: true,
    },
    textMaterialsFilterParams: {
      ...materialsFilterParams,
      showReset: true,
      filterBy: 'resultFilters',
    },
    brandFilterParams: {
      displaySearchBar: true,
      displayHeaders: true,
      displaySelectedBrands: false,
      filterBy: 'resultFilters',
    },
    sizeFilterParams: {
      filterBy: 'resultFilters',
    },
    sortFilterParams: {
      mainElementTag: 'div',
      containerClassName: 'yesplz-brand-filter',
      activeClass: 'active',
      templates: {
        layout: `
          <ul>
          {{#each items}}
            <li data-sort="{{this.value}}"><span>{{this.label}}</span></li>
          {{/each}}
          </ul>
        `,
      },
      items: [
        { label: [{ lng: 'ko', label: '신상순' }, { lng: 'en', label: 'Newest' }], value: 'newest' },
        { label: [{ lng: 'ko', label: '고가순' }, { lng: 'en', label: 'From highest price' }], value: 'price:desc' },
        { label: [{ lng: 'ko', label: '저가순' }, { lng: 'en', label: 'From lowest price' }], value: 'price:asc' },
        { label: [{ lng: 'ko', label: '할인율순' }, { lng: 'en', label: 'Discount' }], value: 'sales_percent' },
      ],
    },
    priceFilterParams: {
      filterBy: 'resultFilters',
    },
    chipsFilterParams: {},
    presetsParams: {},
    onRendered: () => {},
    onCloseClick: () => {},
    onApplyClick: () => {},
    onOpen: () => {},
    onClose: () => {},
    onOpened: () => {},
    onClosed: () => {},
    headerClearAllText: [
      { lng: "en", label: 'Clear all' },
      { lng: "ko", label: "초기화" }
    ],
    styleFilterActionLabel: [
      { lng: "en", label: "Clear" },
      { lng: "ko", label: "초기화" }
    ],
    useChips: true,
    useOnboarding: false,
    scrollContainer: null,
    showResultButtonLeft: [
      { lng: "en", label: "Show " },
      { lng: "ko", label: "" }
    ],
    showResultButtonRight: [
      { lng: "en", label: " Results" },
      { lng: "ko", label: "개 적용" }
    ],
    showResultButtonSvg: ``,
    // showResultButtonSvg: `
    //   <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    //     <path d="M12.6518 0.767578L11.8563 1.56306L15.2312 4.93795H0.5625V6.06299H15.2311L11.8563 9.43778L12.6518 10.2333L17.3847 5.50042L12.6518 0.767578Z" fill="white"/>
    //   </svg>
    // `,
    title: 'Filters',
    onboardingTexts: {
      title: [{ "lng": "en", "label": "Customize your search" }, { "lng": "ko", "label": "원하는 스타일 찾기" }],
      message: [
        { "lng": "en", "label": "Go ahead and tap the body part<br />you want to customize" },
        { "lng": "ko", "label": "바디 부분을 선택 후, 주어진 스타일 옵션 중 에서 원하는 스타일을 선택해보세요." }
      ],
      button: [{ "lng": "en", "label": "Got it!" }, { "lng": "ko", "label": "나가기" }],
    },
    showFooterClearAll: false,
    displaFilterValues: true,
  };

  constructor(params) {
    super(params);

    this.container.classList.add('CollapsibleFilters-container');

    this.mainElement = document.createElement('div');

    if (params.filters) {
      this.params.filters = params.filters;
    }

    if (this.params.useOnboarding) {
      this.params.useOnboarding = window.localStorage.getItem(ONBOARDING_LOCAL_STORAGE_KEY) !== 'Y';
    }

    this.popper = null;

    this.currentlyOpenedFilters = [];
  }

  didMount() {
    setTimeout(() => {
      for (const filter of this.params.filters) {
        if (typeof this[`mount${upperFirst(filter)}Widget`] === 'function') {
          this[`mount${upperFirst(filter)}Widget`]();
        }
        else if (filter.includes('dynamic') && filter !== 'dynamicTreeMenu') {
          this.mountDynamicWidget(filter);
        }
        else {
          this.mountWidget(filter);
        }
      }

      if (this.params.useChips)
        this.mountWidget('chips');

      this.renderFilterValues();
      this.renderFilterActions();

      const collapsibles = document.querySelectorAll('.Collapsible');
      const savedOpenedFilters = this.getSavedOpenedFilters();
      let filtersOpenByDefault = this.params.filtersOpenByDefault;
      if (window.innerWidth <= 768 && !isEmpty(this.params.filtersOpenByDefaultMobile)) {
        filtersOpenByDefault = this.params.filtersOpenByDefaultMobile;
      }
      filtersOpenByDefault = !isEmpty(savedOpenedFilters) ? savedOpenedFilters : filtersOpenByDefault;
      this.saveOpenedFilters(filtersOpenByDefault);
      collapsibles.forEach(collapsible => {
        const collapsibleName = collapsible.getAttribute('data-filter');
        if (filtersOpenByDefault?.includes(collapsibleName)) {
          const content = collapsible.querySelector('.Collapsible-content');
          collapsible.classList.add('open');
          content.style.maxHeight = "none";
          if (typeof this.params.onCollapsibleOpened === 'function')
            this.params.onCollapsibleOpened(collapsibleName)
        }
      });

      const scrollContainer =
        this.params.scrollContainer
          ? this.params.scrollContainer
          : window.innerWidth <= 768
            ? this.container.querySelector('.CollapsibleFilters-Foldable')
            : this.container;

      const header = this.container.querySelector('.CollapsibleFilters-header')
      if (scrollContainer) {
        scrollContainer.addEventListener('scroll', (e) => {
          const container = e.target;
          const containerRect = container.getBoundingClientRect();
          const containerOffsetY = containerRect.y;
          collapsibles.forEach((collapsible, index) => {
            const filterName = collapsible.getAttribute('data-filter');
            if (this.params.stickyHeaders.includes(filterName) && collapsible.classList.contains('open')) {
              const { y } = collapsible.getBoundingClientRect();
              const relativeY = y - containerOffsetY - (header ? header.offsetHeight : 0);
              if (relativeY < 0) {
                collapsible.classList.add('is-sticky');
              }
              else {
                collapsible.classList.remove('is-sticky');
              }
            }
          });
        });
      }

      if (typeof this.params.onApplyClick === 'function') {
        const applyButton = this.container.querySelector('#collapsible-apply-button');
        if (applyButton) {
          applyButton.addEventListener('click', () => {
            this.params.onApplyClick();
            this.close();
          });
        }
      }

      const clearButtons = document.querySelectorAll('#collapsible-clear-button, #collapsible-footer-clear-button');
      if (clearButtons) {
        clearButtons.forEach(clearButton => {
          clearButton.addEventListener('click', this.handleClearAll);
        });
      }

      const closeButton = document.getElementById('collapsible-close-button');
      if (closeButton) {
        closeButton.addEventListener('click', () => {
          this.close();
          if (typeof this.params.onCloseClick === 'function') {
            this.params.onCloseClick();
          }
        });
      }

      const externalOpener = this.params.externalOpener && document.querySelector(this.params.externalOpener);
      if (externalOpener) {
        externalOpener.addEventListener('click', (e) => {
          e.stopPropagation();
          e.preventDefault();
          this.open();
        });
      }

      const totalFoundContainer = document.getElementById('collapsible-total-found');
      if (totalFoundContainer) {
        this.main.addWidget(
          this.main.widgets.TotalFound({
            container: '#collapsible-total-found',
          })
        );
      }

      this.backdrop = document.getElementById('collapsible-backdrop');
      if (this.backdrop)
        this.backdrop.addEventListener('click', () => this.close());

      if (typeof this.params.onRendered === 'function') {
        this.params.onRendered();
      }

      // Set the height
      // if (window.innerWidth <= 768) {
      //   console.log(window.innerHeight);
      //   console.log(this.mainElement);
      //   this.mainElement.style.height = window.innerHeight - 75;
      // }
    }, 100);
  }

  didUpdate(prevState) {
    if (prevState.filter.categoryId !== this.state.filter.categoryId &&
      this.params.filters.includes('bodyParts')) {
      this.remountBodyPartsWidget(prevState);
    }
    this.renderFilterValues(prevState);
  }

  getSavedOpenedFilters() {
    const filters = this.state?.filter?.cfo;
    return filters ? (typeof filters === 'string' ? [filters] : filters) : null;
  }

  saveOpenedFilters(filters) {
    filters = uniq(filters);
    this.currentlyOpenedFilters = filters;
    this.setFilter({
      ...this.state.filter,
      cfo: filters,
    });
  }

  showPopTooltip() {
    const { bodyPart } = this.state.filter;
    const tooltip = this.mainElement.querySelector('#collapsible-popper-tooltip');
    const vfSvg = this.mainElement.querySelector(`#visualfilter-svg`);
    const touchPoint = this.mainElement.querySelector(`#visualfilter-svg #${bodyPart}_point`);
    const vfSvgRect = vfSvg.getBoundingClientRect();
    const touchPointRect = touchPoint.getBoundingClientRect();

    tooltip.classList.remove('before-start');

    this.popper = createPopper(vfSvg, tooltip, {
      placement: `bottom-${(vfSvgRect.width / 2) > touchPointRect.left ? 'start' : 'end'}`,
    });

    const closeButtons = tooltip.querySelectorAll('span[data-close-button]');
    closeButtons.forEach(close => close.addEventListener('click', () => {
      this.removePopTooltip();
    }))
  }

  removePopTooltip() {
    const tooltip = this.mainElement.querySelector('#collapsible-popper-tooltip');

    tooltip.remove();

    window.localStorage.setItem(ONBOARDING_LOCAL_STORAGE_KEY, 'Y');
    this.params.useOnboarding = false;
  }

  handleClearAll = () => {
    const filter = this.main.getFilter();
    const { parts } = this.main.categories[filter.categoryId];
    const params = {};

    parts.forEach(part => {
      params[part.name] = 'all';

      if (!part.isPublished) params[part.name] = part.disabledValue;
    });

    this.setFilter(Hooks.call('widget.CollapsibleClearAll', {
      presetIndex: null,
      occasion: null,
      mood: null,
      params,
    }));

    EventEmitter.emit('collapsibleClearClicked');
  }

  handlePresetsRendered = (presets) => {
    const presetsCollapsible = this.container.querySelector('[data-filter="presets"]');
    if (presets.length > 0) {
      presetsCollapsible.style.display = 'block';
    }
    else {
      presetsCollapsible.style.display = 'none';
    }
  }
  
  handleColorsUpdated = (colors) => {
    const collapsible = this.container.querySelector('[data-filter="colors"]');
    const activeColors = colors.filter(c => c.isActive);
    if (activeColors.length > 0) {
      collapsible.style.display = 'block';
    }
    else {
      collapsible.style.display = 'none';
    }
  }

  handlePatternsRendered = (types) => {
    const patternsCollapsible = this.container.querySelector('[data-filter="patterns"]');
    if (types.length > 0) {
      patternsCollapsible.style.display = 'block';
    }
    else {
      patternsCollapsible.style.display = 'none';
    }
  }

  handleMaterialsRendered = (types) => {
    const patternsCollapsible = this.container.querySelector('[data-filter="materials"]');
    if (types.length > 0) {
      patternsCollapsible.style.display = 'block';
    }
    else {
      patternsCollapsible.style.display = 'none';
    }
  }

  handleTextMaterialsRendered = (materials) => {
    const collapsible = this.container.querySelector('[data-filter="textMaterials"]');
    const activeMaterials = materials.filter(m => m.isActive);
    if (activeMaterials.length > 0) {
      collapsible.style.display = 'block';
    }
    else {
      collapsible.style.display = 'none';
    }
  }

  handleOccasionsRendered = (occasions) => {
    const occasionsCollapsible = this.container.querySelector('[data-filter="occasions"]');
    if (occasions.length > 0) {
      occasionsCollapsible.style.display = 'block';
    }
    else {
      occasionsCollapsible.style.display = 'none';
    }
  }

  handleVibesRendered = (vibes) => {
    const vibesCollapsible = this.container.querySelector('[data-filter="vibes"]');
    if (vibes.length > 0) {
      vibesCollapsible.style.display = 'block';
    }
    else {
      vibesCollapsible.style.display = 'none';
    }
  }

  handleWashRendered = (washTypes) => {
    const washCollapsible = this.container.querySelector('[data-filter="wash"]');
    if (washTypes.length > 0) {
      washCollapsible.style.display = 'block';
    }
    else {
      washCollapsible.style.display = 'none';
    }
  }

  handleBrandRendered = (brands) => {
    const brandCollapsible = this.container.querySelector('[data-filter="brand"]');
    if (brands.length > 0) {
      brandCollapsible.style.display = 'block';
    }
    else {
      brandCollapsible.style.display = 'none';
    }
  }

  handleSizeRendered = (sizes) => {
    const sizeCollapsible = this.container.querySelector('[data-filter="size"]');
    const activeSizes = sizes.filter(s => s.isActive);
    if (activeSizes.length > 0) {
      sizeCollapsible.style.display = 'block';
    }
    else {
      sizeCollapsible.style.display = 'none';
    }
  }

  handleShippingRendered = (shippings) => {
    const shippingCollapsible = this.container.querySelector('[data-filter="shipping"]');
    if (shippings.length > 0) {
      shippingCollapsible.style.display = 'block';
    }
    else {
      shippingCollapsible.style.display = 'none';
    }
  }

  handleDiscountsRendered = (discounts) => {
    const collapsible = this.container.querySelector('[data-filter="discounts"]');
    if (discounts.length > 0) {
      collapsible.style.display = 'block';
    }
    else {
      collapsible.style.display = 'none';
    }
  }

  handleDynamicRendered = (filterName, items) => {
    const collapsible = this.container.querySelector(`[data-filter="${filterName}"]`);
    if (items.length > 0) {
      collapsible.style.display = 'block';
    }
    else {
      collapsible.style.display = 'none';
    }
  }

  handlePriceRendered = (range) => {
    const collapsible = this.container.querySelector('[data-filter="price"]');
    if (range.min === 0 && range.max === 0) {
      collapsible.style.display = 'none';
    }
    else {
      collapsible.style.display = 'block';
    }
  }

  handleSubcategoriesRendered = (subcategories) => {
    const collapsible = this.container.querySelector('[data-filter="subcategories"]');
    const title = collapsible.querySelector('.Collapsible-header-title h3');
    title.innerHTML = findLabelByLng(
      this.main.categories[this.state.filter.categoryId].label,
      this.state.config.lng
    ).replace('Women', '').replace('Men', '');

    if (subcategories.length > 0) {
      collapsible.style.display = 'block';
    }
    else {
      collapsible.style.display = 'none';
    }
  }

  handleSVGHide = () => {
    const styleCollapsible = document.querySelector('div[data-filter="style"]');
    styleCollapsible.classList.add('is-hidden');
  }

  handleSVGLoaded = () => {
    const styleCollapsible = document.querySelector('div[data-filter="style"]');
    if (styleCollapsible) styleCollapsible.classList.remove('is-hidden');
    if (this.params.useOnboarding) {
      setTimeout(() => this.showPopTooltip(), 50);
    }
  }

  mountStyleWidget() {
    this.main.addWidget(
      this.main.widgets.VisualFilter({
        container: '#collapsible-visual-filter',
        ...this.params.visualFilterParams,
        onSVGLoaded: (...args) => {
          if (typeof this.params.visualFilterParams.onSVGLoaded === 'function') {
            this.params.visualFilterParams.onSVGLoaded(...args);
          }
          this.handleSVGLoaded();
        },
        onSVGHide: (...args) => {
          if (typeof this.params.visualFilterParams.onSVGHide === 'function') {
            this.params.visualFilterParams.onSVGHide(...args);
          }
          this.handleSVGHide();
        },
        onTouchClick: (...args) => {
          if (typeof this.params.visualFilterParams.onTouchClick === 'function') {
            this.params.visualFilterParams.onTouchClick(...args);
          }
          if (this.params.useOnboarding) {
            this.removePopTooltip();
          }
        },
        showResetButton: false,
      })
    );

    this.main.addWidget(
      this.main.widgets.StyleFilter({
        container: '#collapsible-style-filter',
        ...this.params.styleFilterParams,
      })
    );

    if (this.params.usePresetFilter) {
      this.main.addWidget(
        this.main.widgets.EditorsPicks({
          container: '#collapsible-editors-pick-filter',
          ...this.params.presetsParams,
        })
      );
    }
  }

  remountBodyPartsWidget(prevState) {
    this.unmountBodyPartsWidget(prevState);
    this.mountBodyPartsWidget();
  }

  unmountBodyPartsWidget(prevState) {
    const { categoryId } = prevState.filter;
    const { parts } = this.main.categories[categoryId];
    for (const part of parts) {
      const collapsible = this.mainElement.querySelector(`.Collapsible[data-filter="body-part-${part.name}"]`);
      collapsible.remove();
    }
  }

  mountBodyPartsWidget() {
    setTimeout(() => {
      const { categoryId } = this.state.filter;
      const { parts, tn: partValues } = this.main.categories[categoryId];
  
      // TODO: Get the correct position to insert the collapsible
      const afterFilter = this.mainElement.querySelector('.Collapsible[data-filter="colors"]');
      for (const part of parts) {
        const collapsible = this.renderCollapsible(`body-part-${part.name}`, part.label);
        afterFilter.parentNode.insertBefore(collapsible, afterFilter);
        collapsible.classList.add('open');
        collapsible.querySelector('.Collapsible-content').style.maxHeight = 'none';
        const values = Object.entries(partValues[part.name]).map(([value, { label }]) => ({
          value, label,
        }))
        this.main.addWidget(
          this.main.widgets.BodyPartList({
            container: `#collapsible-body-part-${part.name}-filter`,
            listName: part.name,
            items: values,
          })
        );
      }
    }, 100);
  }

  mountWidget(filter) {
    const widgetName = WIDGETS_MAP[filter];
    if (widgetName && this.main.widgets[widgetName]) {
      this.main.addWidget(
        this.main.widgets[widgetName]({
          container: `#collapsible-${filter}-filter`,
          ...this.params[`${filter}FilterParams`],
          ...(filter === 'colors' ? { onUpdated: this.handleColorsUpdated } : {}),
          ...(filter === 'patterns' ? { onRendered: this.handlePatternsRendered } : {}),
          ...(filter === 'materials' ? { onRendered: this.handleMaterialsRendered } : {}),
          ...(filter === 'textMaterials' ? { onRendered: this.handleTextMaterialsRendered } : {}),
          ...(filter === 'presets' ? { onRendered: this.handlePresetsRendered } : {}),
          ...(filter === 'occasions' ? { onRendered: this.handleOccasionsRendered } : {}),
          ...(filter === 'vibes' ? { onRendered: this.handleVibesRendered } : {}),
          ...(filter === 'wash' ? { onRendered: this.handleWashRendered } : {}),
          ...(filter === 'subcategories' ? { onRendered: this.handleSubcategoriesRendered } : {}),
          // ...(filter === 'brand' ? { onRendered: this.handleBrandRendered } : {}),
          ...(filter === 'size' ? { onRendered: this.handleSizeRendered } : {}),
          ...(filter === 'shipping' ? { onRendered: this.handleShippingRendered } : {}),
          ...(filter === 'discounts' ? { onRendered: this.handleDiscountsRendered } : {}),
          ...(filter === 'price' ? { onRendered: this.handlePriceRendered } : {}),
          ...(filter !== 'dynamicRange' && filter.includes('dynamic') ? { onRendered: this.handleDynamicRendered.bind(this, filter) } : {}),
        })
      );
    }
  }

  mountDynamicWidget(filter) {
    const widgetName = filter.includes('Range') ? WIDGETS_MAP['dynamicRange'] : WIDGETS_MAP['dynamic'];
    this.main.addWidget(
      this.main.widgets[widgetName]({
        container: `#collapsible-${filter}-filter`,
        ...this.params[`${filter}FilterParams`],
        onRendered: this.handleDynamicRendered.bind(this, 'filter'),
      })
    );
  }

  handleDynamicRendered = (filter, items = []) => {
    const collapsible = this.container.querySelector(`[data-filter="${filter}"]`);
    if (collapsible) {
      if (items.length > 0) {
        collapsible.style.display = 'block';
      }
      else {
        collapsible.style.display = 'none';
      }
    }
  }

  getCategoriesFilterValue(prevState, state) {
    const category = this.main.categories[state.filter.categoryId];
    if (category && (prevState.filter?.categoryId !== state.filter.categoryId)) {
      
      return category.label;
    }

    return false;
  }

  getPresetsFilterValue(prevState, state) {
    if (prevState.filter?.presetIndex !== state.filter.presetIndex) {
      return state.filter.presetIndex || '';
    }
    return false;
  }

  getOccasionsFilterValue(prevState, state) {
    if (prevState.filter?.occasion !== state.filter.occasion) {
      if (!state.filter.occasion) return '';

      const category = this.main.categories[state.filter.categoryId];
      const label = (category.occasionsList || []).reduce((label, occasion) => {
        if (occasion.value === state.filter.occasion) return occasion.label;
        return label;
      }, '');

      return label;
    }
    return false;
  }

  getVibesFilterValue(prevState, state) {
    if (prevState.filter?.params && prevState.filter.params.vibe !== state.filter.params.vibe) {
      if (!state.filter.params.vibe) return '';

      const category = this.main.categories[state.filter.categoryId];
      const label = (category.vibesList || []).reduce((label, vibe) => {
        if (vibe.value === state.filter.params.vibe) return vibe.label;
        return label;
      }, '');

      return label;
    }
    return false;
  }

  getMoodsFilterValue(prevState, state) {
    if (prevState.filter?.mood !== state.filter.mood) {
      if (!state.filter.mood) return '';

      const category = this.main.categories[state.filter.categoryId];
      const label = (category.moodsList || []).reduce((label, mood) => {
        if (mood.value === state.filter.mood) return mood.label;
        return label;
      }, '');

      return label;
    }
    return false;
  }

  getPatternsFilterValue(prevState, state) {
    const { lng } = this.state.config;
    const { design: prevPatterns } = prevState.filter?.params || {};
    const { design: patterns = [] } = state.filter.params;
    const category = this.main.categories[state.filter.categoryId];
    if (category && (!prevPatterns || !isEqual(prevPatterns, patterns))) {
      const availablePatterns = category.patterns || [];
      const appliedPatterns = availablePatterns
        .filter(pattern => patterns.includes(pattern.value))
        .map(pattern => findLabelByLng(pattern.label, lng));
      return appliedPatterns.join(', ');
    }
    else if (patterns.length === 0) {
      return '';
    }
    return false;
  }

  getMaterialsFilterValue(prevState, state) {
    const { lng } = this.state.config;
    const { material: prevMaterials } = prevState.filter?.params || {};
    const { material: materials = [] } = state.filter.params;
    const category = this.main.categories[state.filter.categoryId];
    if (!prevMaterials || !isEqual(prevMaterials, materials)) {
      const availableMaterials = category.materials;
      const appliedMaterials = availableMaterials
        .filter(material => materials.includes(material.value))
        .map(material => findLabelByLng(material.label, lng));
      return appliedMaterials.join(', ');
    }
    else if (materials.length === 0) {
      return '';
    }
    return false;
  }

  getColorsFilterValue(prevState, state) {
    const { color: prevColors } = prevState.filter?.params || {};
    const { color: colors = [] } = state.filter.params;
    if (!prevColors || !isEqual(prevColors, colors)) {
      return colors.map(color => upperFirst(color)).join(', ');
    }
    else if (colors.length === 0) {
      return '';
    }
    return false;
  }

  getPriceFilterValue(prevState, state) {
    const { price: prevPrice } = prevState.filter?.params || {};
    const { price } = state.filter.params;
    if (
      (!prevPrice || prevPrice !== price)
      ||
      !isEqual(prevState.search?.filters?.salePrice, state.search?.filters?.salePrice)
    ) {
      if (!price || price === '0-') return '';
      const [low, high] = price.split('-');

      let currency = this.params.priceFilterParams.currency || {};
      if (state.search?.filters?.salePrice?.currency) {
        currency = {
          code: state.search?.filters?.salePrice?.currency,
        };
      }
      
      return [
        low ? formatCurrency(parseInt(low), currency) : formatCurrency(0, currency),
        high ? formatCurrency(parseInt(high), currency) : findLabelByLng(
          [
            { message: ' or more', lng: 'en'},
            { message: ' 이상', lng: 'kr'},
          ],
          this.state.config.lng
        ),
      ].join(high ? ' - ' : '');
    }
    return false;
  }

  getBrandFilterValue(prevState, state) {
    const { brands: prevBrands } = prevState.filter?.params || {};
    const { brands = [] } = state.filter.params;
    if (!prevBrands || !isEqual(prevBrands, brands)) {
      return brands.join(', ');
    }
    else if (brands.length === 0) {
      return '';
    }
    return false;
  }

  getSortFilterValue(prevState, state) {
    const { sort: prevSort } = prevState.filter || {};
    const { sort } = state.filter;
    if (!prevSort || prevSort !== sort) {
      if (!sort) return '';

      const lng = this.state.config.lng;
      const sortItem = find(this.params.sortFilterParams.items, { value: sort });

      return findLabelByLng(sortItem?.label, lng);
    }
    return false;
  }

  renderFilterValues(prevState = {}) {
    if (!this.params.displaFilterValues) return;

    for (const filterName of this.params.filters) {
      if (typeof this[`get${upperFirst(filterName)}FilterValue`] === 'function') {
        const value = this[`get${upperFirst(filterName)}FilterValue`](prevState, this.state);
        if (typeof value === 'string') {
          const titleElement = document.getElementById(`${filterName}-filter-value`);
          if (titleElement) titleElement.innerHTML = value.length ? `(${value})` : value;
        }
      }
    }
  }

  getStyleFilterAction() {
    const { lng } = this.state.config;
    return findLabelByLng(this.params.styleFilterActionLabel, lng);
  }

  handleStyleFilterActionClick = (e) => {
    e.stopPropagation();
    const filter = this.main.getFilter();
    const { parts } = this.main.categories[filter.categoryId];
    const params = filter.params;

    parts.forEach(part => {
      params[part.name] = 'all';

      if (!part.isPublished) params[part.name] = part.disabledValue;
    });
    this.setFilter({
      presetIndex: null,
      occasion: null,
      params,
    });

    EventEmitter.emit('collapsibleBodyPartsClearClicked');
  }

  renderFilterActions(prevState = {}) {
    const { filter: prevFilter = {} } = prevState;
    const { filter } = this.state;
    for (const filterName of this.params.filters) {
      const actionName = upperFirst(filterName);
      if (typeof this[`get${actionName}FilterAction`] === 'function') {
        const label = this[`get${actionName}FilterAction`](prevFilter, filter);
        if (typeof label === 'string') {
          const actionElement = document.getElementById(`${filterName}-filter-action`);
          if (actionElement) {
            actionElement.innerHTML = label;
            actionElement.addEventListener('click', this[`handle${actionName}FilterActionClick`]);
          }
        }
      }
    }
  }

  renderCollapsible(filter, label = null) {
    const { lng } = this.state.config;
    let htmlContent = `<div id="collapsible-${filter}-filter"></div>`;
    if (filter === 'style') {
      htmlContent = '<div id="collapsible-visual-filter"></div>' + htmlContent;

      if (this.params.usePresetFilter) {
        htmlContent = '<div id="collapsible-editors-pick-filter"></div>' + htmlContent;
      }
    }

    const collapsible = document.createElement('div');
    collapsible.className = `Collapsible`;
    collapsible.setAttribute('data-filter', filter);
    collapsible.innerHTML = `
      <div class="Collapsible-header">
        <span class="plus"></span>
        <div class="Collapsible-header-title">
          <h3>${findLabelByLng(label || this.params.filterLabels[filter], lng)}</h3>
          <span class="filter-value" id="${filter}-filter-value"></span>
          <span class="filter-spreader"></span>
          <span class="filter-action" id="${filter}-filter-action"></span>
        </div>
      </div>
      <div class="Collapsible-content">
        ${htmlContent}
      </div>
    `;

    collapsible.querySelector('.Collapsible-header').addEventListener('click', (e) => {
      const content = collapsible.querySelector('.Collapsible-content');
      if (collapsible.classList.contains('open')) {
        collapsible.classList.remove('open');
        collapsible.classList.remove('is-sticky');
        content.style.maxHeight = content.scrollHeight + "px";
        setTimeout(() => {
          content.style.maxHeight = null;

          const transitionend = () => {
            content.removeEventListener('transitionend', transitionend);

            if (typeof this.params.onCollapsibleClosed === 'function')
              this.params.onCollapsibleClosed(filter);

            if (this.popper) {
              this.popper.update();
            }
          }
          content.addEventListener('transitionend', transitionend);
        }, 0);
        this.saveOpenedFilters(this.currentlyOpenedFilters.filter(f => f !== filter));
      }
      else {
        collapsible.classList.add('open');
        collapsible.classList.add('is-animating');
        content.style.maxHeight = content.scrollHeight + "px";

        const transitionend = () => {
          collapsible.classList.remove('is-animating');
          content.style.maxHeight = "none";
          content.removeEventListener('transitionend', transitionend);

          if (typeof this.params.onCollapsibleOpened === 'function')
            this.params.onCollapsibleOpened(filter);

          // if (!isInViewport(content)) {
          //   content.scrollIntoView();
          // }
          if (this.popper) {
            this.popper.update();
          }
        }
        content.addEventListener('transitionend', transitionend);
        this.saveOpenedFilters([...this.currentlyOpenedFilters, filter]);
      }
    });

    return collapsible;
  }

  open() {
    const slider = this.mainElement;
    slider.style.transform = `translate3d(0, 0, 0)`;
    this.backdrop.classList.remove('is-hidden');

    if (typeof this.params.onOpen === 'function') {
      this.params.onOpen();
    }

    const onOpened = () => {
      document.body.style.overflow = 'hidden';
      slider.removeEventListener('transitionend', onOpened);

      EventEmitter.emit('collapsibleMobileOpened');

      if (typeof this.params.onOpened === 'function') {
        this.params.onOpened();
      }
    };
    slider.addEventListener('transitionend', onOpened);
  }

  close() {
    const slider = this.mainElement;
    const { height } = slider.getBoundingClientRect();
    const translation = height;

    slider.style.transform = `translate3d(0, ${translation}px, 0)`;

    this.backdrop.classList.add('is-hidden');

    if (typeof this.params.onClose === 'function') {
      this.params.onClose();
    }

    const onClosed = () => {
      document.body.style.overflow = 'visible';
      slider.removeEventListener('transitionend', onClosed);

      EventEmitter.emit('collapsibleMobileClosed');

      if (typeof this.params.onClosed === 'function') {
        this.params.onClosed();
      }
    };
    slider.addEventListener('transitionend', onClosed);
  };

  render() {
    const { lng } = this.state.config;

    if (this.params.useChips) {
      this.mainElement.insertAdjacentHTML('beforeend', `
        <div class="Collapsible-header-chips" id="collapsible-chips-filter"></div>
      `);
    }

    this.mainElement.classList.add('CollapsibleFilters');
    for (const filter of this.params.filters) {
      if (!filter || filter === 'bodyParts') continue;
      this.mainElement.appendChild(this.renderCollapsible(filter));
    }

    if (this.params.isFoldable) {
      this.mainElement.classList.add('CollapsibleFilters-Foldable');
    }

    if (this.params.useHeader) {
      this.mainElement.insertAdjacentHTML('afterbegin', `
        <div class="CollapsibleFilters-header">
          <div class="CollapsibleFilters-header-actions">
            <h3>${findLabelByLng(this.params.title, lng)}</h3>
            <button id="collapsible-clear-button">${findLabelByLng(this.params.headerClearAllText, lng)}</button>
          </div>
          <div id="collapsible-close-button" class="CollapsibleFilters-header-close">
            <img src="${closeSvg}" alt="" />
          </div>
        </div>
      `);
    }

    if (this.params.useFooter) {
      let clearButtonHtml = '';
      if (this.params.showFooterClearAll) {
        clearButtonHtml = `
          <button id="collapsible-footer-clear-button" className="CollapsibleFilters-footer-button">
            ${findLabelByLng(this.params.headerClearAllText, lng)}
          </button>
        `;
      }
      this.mainElement.insertAdjacentHTML('beforeend', `
        <div class="CollapsibleFilters-footer">
          ${clearButtonHtml}
          <button id="collapsible-apply-button" className="CollapsibleFilters-footer-button">
            ${findLabelByLng(this.params.showResultButtonLeft, lng)}<span id="collapsible-total-found"></span>${findLabelByLng(this.params.showResultButtonRight, lng)}
            ${this.params.showResultButtonSvg}
          </button>
        </div>
      `);      
    }
    if (this.params.useOnboarding) {
      const { lng } = this.state.config;
      this.mainElement.insertAdjacentHTML('beforeend', `
        <div class="CollapsibleFilters-tooltip before-start" id="collapsible-popper-tooltip">
          <span data-close-button class="CollapsibleFilters-tooltip-close">
            <img src="${tooltipCloseSvg}" alt="" />
          </span>
          <h4>${findLabelByLng(this.params.onboardingTexts.title, lng)}</h4>
          <p>${findLabelByLng(this.params.onboardingTexts.message, lng)}</p>
          <div class="CollapsibleFilters-tooltip-footer">
            <span data-close-button class="CollapsibleFilters-tooltip-button">
              ${findLabelByLng(this.params.onboardingTexts.button, lng)}
            </span>
          </div>
        </div>
      `);
    }
    this.container.insertAdjacentHTML('afterbegin', `
      <div id="collapsible-backdrop" class="CollapsibleFilters-backdrop${!this.params.isOpenInitialy ? ' is-hidden' : ''}"></div>
    `);

    if (!this.params.isOpenInitialy && this.params.isFoldable) {
      this.mainElement.style.transform = `translate3d(0, 100vh, 0)`;
    }

    this.mainElement.setAttribute('data-category-id', this.state.filter.categoryId);

    return this.mainElement;
  }
}

export default (params) => {
  return new CollapsibleFilters(params);
};
