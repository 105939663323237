import upperFirst from 'lodash/upperFirst';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';
import take from 'lodash/take';
import {
  patternsFilterParams,
  materialsFilterParams,
} from '../extended-configuration';
import closeIcon from '@yesplz/core-web/assets/images/close-icon-white.png';
import arrowRightSvg from '@yesplz/core-web/assets/svg/mobile-tabbed-arrow-right.svg';
import arrowLeftSvg from '@yesplz/core-web/assets/svg/mobile-tabbed-arrow-left.svg';
import resetIconSvg from '@yesplz/core-models/assets/svg/reset-icon.svg';
import { findLabelByLng } from '@yesplz/core';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';
import Hooks from '../modules/Hooks';

const { document } = window;

export const WIDGETS_MAP = {
  treeMenu: 'TreeViewMenu',
  subcategories: 'SubcategoriesList',
  presets: 'SimplePresetsList',
  occasions: 'SimpleOccasionsList',
  subtypes: 'SimpleSubtypesList',
  vibes: 'SimpleVibesList',
  moods: 'SimpleMoodsList',
  patterns: 'DesignFilter',
  colors: 'ColorFilter',
  materials: 'MaterialFilter',
  textMaterials: 'MaterialTextList',
  price: 'PriceRangeFilter',
  brand: 'BrandFilter',
  size: 'SizeFilter',
  discounts: 'DiscountsFilter',
  shipping: 'ShippingOptionsFilter',
  wash: 'WashFilter',
  sort: 'SortBy',
  newArrivals: 'NewArrivalsFilter',
  'dynamic': 'DynamicList',
};

class Categories extends Widget {
  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className =
      this.params.containerClassName || 'MobileTabbed-tree-view';
    this.mainElement = element;

    this.vmfWidget = null;
  }

  didMount() {
    this.setActiveLevel();
  }

  didUpdate(prevState) {
    if (prevState.filter.categoryId !== this.state.filter.categoryId) {
      this.setActiveLevel();
    }
  }

  setActiveLevel() {
    function updateOpenClassRecursively(listElement) {
      if (listElement.tagName !== 'LI') return;
      listElement.classList.add('is-open');
      updateOpenClassRecursively(listElement.parentElement.parentElement);
    }
    const { categoryId } = this.state.filter;
    const foundElements = this.mainElement.querySelectorAll(
      `li[data-tree-item-catid="${categoryId}"]`
    );
    if (foundElements && foundElements.length) {
      const activeElement = foundElements[0];
      updateOpenClassRecursively(activeElement);
      const level = activeElement.getAttribute('data-tree-item-level');
      if (level !== '1') {
        this.mainElement.classList.add(`open-level-${level - 1}`);
      }

      document
        .querySelector('.MobileTabbedFilters-tabs')
        .classList.add('low-index');
    }
  }

  setCategoryId(categoryId) {
    if (categoryId && this.main.categories[categoryId]) {
      this.main.setCategory(categoryId);

      if (typeof this.params.onCategorySelected === 'function')
        this.params.onCategorySelected(categoryId);
    }
  }

  openLevel = (item, level, parentItem) => {
    document
      .querySelector('.MobileTabbedFilters-tabs')
      .classList.remove('low-index');

    const parentItemElement =
      parentItem && parentItem.categoryId
        ? (() => {
            const elements = this.mainElement.querySelectorAll(
              `li[data-tree-item-catid="${parentItem.categoryId}"] > ul`
            );
            return elements[elements.length - 1];
          })()
        : this.mainElement.children[0];
    if (item.type === 'back') {
      this.mainElement.classList.remove('open-level-' + level);
      if (!parentItemElement) {
        this.mainElement.classList.remove('open-menu');
      } else {
        this.mainElement.classList.add('open-level-' + (level - 1));
      }
      parentItemElement
        .querySelectorAll(`li.is-open`)
        ?.forEach((el) => el.classList.remove('is-open'));
    } else if (item.type === 'all') {
      this.mainElement.classList.remove('open-level-' + (level - 1));
      this.mainElement.classList.remove('open-menu');
      this.setCategoryId(parentItem.categoryId);
      parentItemElement
        .querySelectorAll(`li.is-open`)
        ?.forEach((el) => el.classList.remove('is-open'));
    } else if (item.children && item.children.length) {
      this.mainElement.classList.add('open-menu');
      this.mainElement.classList.remove('open-level-' + (level - 1));
      this.mainElement.classList.add('open-level-' + level);

      parentItemElement
        .querySelectorAll(`li.is-open`)
        ?.forEach((el) => el.classList.remove('is-open'));

      const foundElements = this.mainElement.querySelectorAll(
        `li[data-tree-item-catid="${item.categoryId}"]`
      );
      if (foundElements && foundElements.length) {
        const openElement = foundElements[0];
        openElement.classList.add('is-open');
      }
    } else {
      this.mainElement.classList.remove('open-level-' + (level - 1));
      this.mainElement.classList.remove('open-menu');
      this.setCategoryId(item.categoryId);
      parentItemElement
        .querySelectorAll(`li.is-open`)
        ?.forEach((el) => el.classList.remove('is-open'));
    }
  };

  renderTree(items, level = 1, parentItem = null) {
    const ul = document.createElement('ul');
    [
      ...(level > 1
        ? [{ label: 'View all', type: 'all', categoryId: null }]
        : []),
      { label: 'Back', type: 'back', categoryId: null },
      ...items,
    ].forEach((item) => {
      const li = document.createElement('li');
      const hasChildren = Array.isArray(item.children) && item.children.length;
      li.innerHTML =
        (item.type === 'back' ? '<i></i>' : '') +
        `
        <span>${findLabelByLng(item.label, this.state.config.lng)}</span>
      ` +
        (hasChildren ? '<i></i>' : '');
      if (item.categoryId) {
        li.setAttribute('data-tree-item-catid', item.categoryId);
      }
      if (item.type) {
        li.setAttribute('data-tree-item-type', item.type);
      }
      li.setAttribute('data-tree-item-level', level);
      if (hasChildren) {
        li.appendChild(this.renderTree(item.children, level + 1, item));
      }

      ul.appendChild(li);
      li.addEventListener('click', (e) => {
        e.stopPropagation();
        this.openLevel(item, level, parentItem);
      });
    });

    return ul;
  }

  render() {
    this.mainElement.appendChild(this.renderTree(this.main.menu));
    return this.mainElement;
  }
}

export class MobileTabbedFilters extends Widget {
  defaultParams = {
    filters: [
      'categories',
      'presets',
      'occasions',
      'style',
      'patterns',
      'colors',
      'brand',
      'price',
    ],
    stickyHeaders: ['brand'],
    filterLabels: {
      treeMenu: [
        { lng: 'en', label: 'Categories' },
        { lng: 'ko', label: '카테고리' },
      ],
      categories: [
        { lng: 'en', label: 'Category' },
        { lng: 'ko', label: '카테고리' },
      ],
      subcategories: [
        { lng: 'en', label: 'Sub Category' },
        { lng: 'ko', label: '카테고리' },
      ],
      occasions: [
        { lng: 'en', label: 'Occasion' },
        { lng: 'ko', label: '기회' },
      ],
      subtypes: [
        { lng: 'en', label: 'Subtype' },
        { lng: 'ko', label: '하위 유형' },
      ],
      vibes: [
        { lng: 'en', label: 'Vibe' },
        { lng: 'ko', label: '바이브' },
      ],
      moods: [
        { lng: 'en', label: 'Mood' },
        { lng: 'ko', label: '무드' },
      ],
      style: [
        { lng: 'en', label: 'Style' },
        { lng: 'ko', label: '스타일' },
      ],
      patterns: [
        { lng: 'en', label: 'Pattern & Feature' },
        { lng: 'ko', label: '패턴' },
      ],
      presets: [
        { lng: 'en', label: 'Product Type' },
        { lng: 'ko', label: '상품 유형' },
      ],
      colors: [
        { lng: 'en', label: 'Color' },
        { lng: 'ko', label: '색상' },
      ],
      patterns: [
        { lng: 'en', label: 'Pattern' },
        { lng: 'ko', label: '패턴' },
      ],
      brand: [
        { lng: 'en', label: 'Brand' },
        { lng: 'ko', label: '브랜드' },
      ],
      materials: [
        { lng: 'en', label: 'Material' },
        { lng: 'ko', label: '소재' },
      ],
      textMaterials: [
        { lng: 'en', label: 'Material' },
        { lng: 'ko', label: '소재' },
      ],
      price: [
        { lng: 'en', label: 'Price' },
        { lng: 'ko', label: '가격' },
      ],
      size: [
        { lng: 'en', label: 'Size' },
        { lng: 'ko', label: '사이즈' },
      ],
      wash: [
        { lng: 'en', label: 'Wash Type' },
        { lng: 'ko', label: '워쉬' },
      ],
      sort: [
        { lng: 'en', label: 'Sort' },
        { lng: 'ko', label: '정렬' },
      ],
      discounts: [
        { lng: 'en', label: 'Discount' },
        { lng: 'ko', label: 'Discount' },
      ],
      shipping: [
        { lng: 'en', label: 'Shipping Options' },
        { lng: 'ko', label: 'Shipping Options' },
      ],
      newArrivals: [
        { lng: 'en', label: 'New Arrivals' },
        { lng: 'ko', label: 'New Arrivals' },
      ],
    },
    usePresetFilter: false,
    useHeader: true,
    useFooter: true,
    isFoldable: true,
    isOpenInitialy: false,
    // stylesAsPrefix: false,
    categoriesFilterParams: {},
    treeMenuFilterParams: {},
    visualFilterParams: {
      svgViewBox: [0, 0, 362, 254],
      svgViewBoxMobile: [0, 0, 362, 254],
      showTooltipsToggler: false,
      presetNavigation: false,
      showParamTags: true,
      tagHighlightColor: '#2F30EB',
      useOnboarding: true,
    },
    styleFilterParams: {},
    patternsFilterParams: {
      ...patternsFilterParams,
      showReset: true,
    },
    colorsFilterParams: {
      resetImage: resetIconSvg,
      displayLabels: true,
    },
    materialsFilterParams: {
      ...materialsFilterParams,
      showReset: true,
    },
    textMaterialsFilterParams: {
      ...materialsFilterParams,
      showReset: true,
    },
    brandFilterParams: {
      displaySearchBar: true,
      displayHeaders: true,
      displaySelectedBrands: false,
    },
    sortFilterParams: {
      mainElementTag: 'div',
      containerClassName: 'yesplz-brand-filter',
      activeClass: 'active',
      templates: {
        layout: `
          <ul>
          {{#each items}}
            <li data-sort="{{this.value}}"><span>{{this.label}}</span></li>
          {{/each}}
          </ul>
        `,
      },
      items: [
        {
          label: [
            { lng: 'ko', label: '신상순' },
            { lng: 'en', label: 'Newest' },
          ],
          value: 'newest',
        },
        {
          label: [
            { lng: 'ko', label: '고가순' },
            { lng: 'en', label: 'From highest price' },
          ],
          value: 'price:desc',
        },
        {
          label: [
            { lng: 'ko', label: '저가순' },
            { lng: 'en', label: 'From lowest price' },
          ],
          value: 'price:asc',
        },
        {
          label: [
            { lng: 'ko', label: '할인율순' },
            { lng: 'en', label: 'Discount' },
          ],
          value: 'sales_percent',
        },
      ],
    },
    priceFilterParams: {},
    dynamicTreeMenuFilterParams: {
      useAllItem: true,
    },
    presetsParams: {},
    onRendered: () => {},
    onCloseClick: () => {},
    onApplyClick: () => {},
    onOpen: () => {},
    onClose: () => {},
    onOpened: () => {},
    onClosed: () => {},
    headerClearAllText: [
      { lng: 'en', label: 'Clear All' },
      { lng: 'ko', label: '초기화' },
    ],
    styleFilterActionLabel: [
      { lng: 'en', label: 'Clear' },
      { lng: 'ko', label: '초기화' },
    ],
    scrollContainer: null,
    showResultButtonLeft: [
      { lng: 'en', label: '' },
      { lng: 'ko', label: '' },
    ],
    showResultButtonRight: [
      { lng: 'en', label: ' Applied' },
      { lng: 'ko', label: '개 적용' },
    ],
    showResultButtonSvg: ``,
    // showResultButtonSvg: `
    //   <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    //     <path d="M12.6518 0.767578L11.8563 1.56306L15.2312 4.93795H0.5625V6.06299H15.2311L11.8563 9.43778L12.6518 10.2333L17.3847 5.50042L12.6518 0.767578Z" fill="white"/>
    //   </svg>
    // `,
    title: 'Filter',
  };

  constructor(params) {
    super(params);

    this.container.classList.add('MobileTabbedFilters-container');

    this.mainElement = document.createElement('div');

    this.mainElement.insertAdjacentHTML(
      'beforeend',
      `
      <div class="MobileTabbedFilters-tabs-wrapper">
        <div class="MobileTabbedFilters-tabs-container">
          <div class="MobileTabbedFilters-tabs-slider">
            <div class="MobileTabbedFilters-tabs-titles">
            </div>
            <div class="MobileTabbedFilters-tabs">
              <div class="MobileTabbedFilters-tabs-content">
              </div>
            </div>
          </div>
        </div>
      </div>
    `
    );

    this.tabTitles = this.mainElement.querySelector(
      '.MobileTabbedFilters-tabs-titles'
    );
    this.tabs = this.mainElement.querySelector(
      '.MobileTabbedFilters-tabs-content'
    );

    this.activeTab = null;

    if (params.filters) {
      this.params.filters = params.filters;
    }
  }

  didMount() {
    this.startCategoryId = this.state.filter.categoryId;
    this.startLevel = this.state.filter.categoryId.split('/').length;

    setTimeout(() => {
      this.params.treeMenuFilterParams = {
        items: this.main.menu,
        useAllItem: true,
        displayAllForSubcategories: true,
      };

      for (const filter of this.params.filters) {
        if (typeof this[`mount${upperFirst(filter)}Widget`] === 'function') {
          this[`mount${upperFirst(filter)}Widget`]();
        } else if (filter.includes('dynamic') && filter !== 'dynamicTreeMenu') {
          this.mountDynamicWidget(filter);
        } else {
          this.mountWidget(filter);
        }
      }

      this.renderFilterValues();

      if (typeof this.params.onApplyClick === 'function') {
        const applyButton = this.container.querySelector(
          '#mobile-tabbed-apply-button'
        );
        if (applyButton) {
          applyButton.addEventListener('click', () => {
            this.params.onApplyClick();
            this.close();
          });
        }
      }

      const clearButtons = document.querySelectorAll(
        '#mobile-tabbed-header-clear-button'
      );
      if (clearButtons) {
        clearButtons.forEach((clearButton) => {
          clearButton.addEventListener('click', this.handleClearAll);
        });
      }

      const closeButton = document.getElementById('mobile-tabbed-close-button');
      if (closeButton) {
        closeButton.addEventListener('click', () => {
          this.close();
          if (typeof this.params.onCloseClick === 'function') {
            this.params.onCloseClick();
          }
        });
      }

      const externalOpener =
        this.params.externalOpener &&
        document.querySelector(this.params.externalOpener);
      if (externalOpener) {
        externalOpener.addEventListener('click', (e) => {
          e.stopPropagation();
          e.preventDefault();
          this.open();
        });
      }

      const totalFoundContainer = document.getElementById(
        'mobile-tabbed-total-found'
      );
      if (totalFoundContainer) {
        this.main.addWidget(
          this.main.widgets.TotalFound({
            container: '#mobile-tabbed-total-found',
          })
        );
      }

      this.main.addWidget(
        this.main.widgets.Chips({
          container: '#mobile-tabbed-chips',
          ...(this.params.chipsFilterParams || {}),
          onRedered: (items) => {
            if (items.length > 0) {
              this.mainElement.classList.add('has-chips');
            } else {
              this.mainElement.classList.remove('has-chips');
            }
          },
        })
      );

      const clearFiltersButtons = document.querySelectorAll(
        '.MobileTabbed-header-action'
      );
      if (clearFiltersButtons) {
        clearFiltersButtons.forEach((clearFiltersButton) => {
          clearFiltersButton.addEventListener('click', this.handleClearFilter);
        });
      }

      if (typeof this.params.onRendered === 'function') {
        this.params.onRendered();
      }
    }, 100);
  }

  didUpdate(prevState) {
    this.renderFilterValues(prevState);
    if (prevState.filter.categoryId !== this.state.filter.categoryId) {
      this.mainElement.setAttribute(
        'data-category-id',
        this.state.filter.categoryId
      );
    }
  }

  handleClearAll = () => {
    const filter = this.main.getFilter();
    const { parts } = this.main.categories[filter.categoryId];
    const params = {};

    parts.forEach((part) => {
      params[part.name] = 'all';

      if (!part.isPublished) params[part.name] = part.disabledValue;
    });

    const categoryIdSplits = this.state.filter.categoryId.split('/');
    if (categoryIdSplits.length > this.startLevel) {
      const newCategoryId = take(
        categoryIdSplits,
        this.startLevel
      ).join('/');
      this.main.setCategory(newCategoryId);
    } else {
      this.setFilter(
        Hooks.call('widget.MobileTabbedClearAll', {
          presetIndex: null,
          occasion: null,
          mood: null,
          params,
        })
      );
    }

    EventEmitter.emit('collapsibleClearClicked');
  }

  handleClearFilter = (e) => {
    const button = e.target;
    const filterName = button.getAttribute('data-clear-filter');
    if (
      typeof this[`handleClear${upperFirst(filterName)}Filter`] === 'function'
    ) {
      this[`handleClear${upperFirst(filterName)}Filter`]();
    }
  };

  handleClearDynamicTreeMenuFilter() {
    this.setFilter({
      params: {
        ...this.state.filter.params,
        subcategory: [],
      },
    });
  }

  handleClearTreeMenuFilter() {
    const categoryIdSplits = this.state.filter.categoryId.split('/');
    const newCategoryId = take(categoryIdSplits, this.startLevel).join('/');
    this.main.setCategory(newCategoryId);
    window.requestAnimationFrame(() => {
      this.setFilter({
        params: {
          ...this.state.filter.params,
          subcategory: [],
        },
      });
    });
  }

  handleClearBrandFilter() {
    const filter = this.main.getFilter();
    const params = filter.params;
    this.setFilter({
      params: {
        ...params,
        brands: [],
      },
    });
  }

  handleClearColorsFilter() {
    const filter = this.main.getFilter();
    const params = filter.params;
    this.setFilter({
      params: {
        ...params,
        color: [],
      },
    });
  }

  handleClearPatternsFilter() {
    const filter = this.main.getFilter();
    const params = filter.params;
    this.setFilter({
      params: {
        ...params,
        design: [],
      },
    });
  }

  handleClearPatternsFilter() {
    const filter = this.main.getFilter();
    const params = filter.params;
    this.setFilter({
      params: {
        ...params,
        design: [],
      },
    });
  }

  handleClearTextMaterialsFilter() {
    const filter = this.main.getFilter();
    const params = filter.params;
    this.setFilter({
      params: {
        ...params,
        materialText: [],
      },
    });
  }

  handleClearVibesFilter() {
    const filter = this.main.getFilter();
    const params = filter.params;
    this.setFilter({
      params: {
        ...params,
        vibe: [],
      },
    });
  }

  handleClearOccasionsFilter() {
    this.setFilter({ occasion: [] });
  }

  handleClearSizeFilter() {
    const filter = this.main.getFilter();
    const params = filter.params;
    this.setFilter({
      params: {
        ...params,
        size: [],
      },
    });
  }

  handleClearDiscountsFilter() {
    const filter = this.main.getFilter();
    const params = filter.params;
    this.setFilter({
      params: {
        ...params,
        discount: [],
      },
    });
  }

  handleClearPriceFilter() {
    const filter = this.main.getFilter();
    const params = filter.params;
    this.setFilter({
      params: {
        ...params,
        price: null,
      },
    });
  }

  handleClearShippingFilter() {
    const filter = this.main.getFilter();
    const params = filter.params;
    this.setFilter({
      params: {
        ...params,
        shippingOption: [],
      },
    });
  }

  handleClearNewArrivalsFilter() {
    const filter = this.main.getFilter();
    const params = filter.params;
    this.setFilter({
      params: {
        ...params,
        newArrivals: [],
      },
    });
  }

  handleClearStyleFilter() {
    const filter = this.main.getFilter();
    const { parts } = this.main.categories[filter.categoryId];
    const params = filter.params;

    parts.forEach((part) => {
      params[part.name] = 'all';

      if (!part.isPublished) params[part.name] = part.disabledValue;
    });
    this.setFilter({
      presetIndex: null,
      occasion: null,
      params,
    });
  }

  handlePresetsRendered = (presets) => {
    const presetsTabTitle = this.container.querySelector(
      '[data-tab-title="presets"]'
    );
    if (presets.length > 0) {
      presetsTabTitle.classList.remove('is-hidden');
    } else {
      presetsTabTitle.classList.add('is-hidden');
    }
  };

  handlePatternsRendered = (types) => {
    const patternsTabTitle = this.container.querySelector(
      '[data-tab-title="patterns"]'
    );
    if (types.length > 0) {
      patternsTabTitle.classList.remove('is-hidden');
    } else {
      patternsTabTitle.classList.add('is-hidden');
    }
  };

  handleMaterialsRendered = (types) => {
    const patternsTabTitle = this.container.querySelector(
      '[data-tab-title="materials"]'
    );
    if (types.length > 0) {
      patternsTabTitle.classList.remove('is-hidden');
    } else {
      patternsTabTitle.classList.add('is-hidden');
    }
  };

  handleTextMaterialsRendered = (materials) => {
    const tabTitle = this.container.querySelector(
      '[data-tab-title="textMaterials"]'
    );
    const activeMaterials = materials.filter(m => m.isActive);
    if (activeMaterials.length > 0) {
      tabTitle.classList.remove('is-hidden');
    } else {
      tabTitle.classList.add('is-hidden');
    }
  };

  handleOccasionsRendered = (occasions) => {
    const tabTitle = this.container.querySelector(
      '[data-tab-title="occasions"]'
    );
    if (occasions.length > 0) {
      tabTitle.classList.remove('is-hidden');
    } else {
      tabTitle.classList.add('is-hidden');
    }
  };

  handleVibesRendered = (vibes) => {
    const tabTitle = this.container.querySelector('[data-tab-title="vibes"]');
    if (vibes.length > 0) {
      tabTitle.classList.remove('is-hidden');
    } else {
      tabTitle.classList.add('is-hidden');
    }
  };

  handleWashRendered = (washTypes) => {
    const washTabTitle = this.container.querySelector(
      '[data-tab-title="wash"]'
    );
    if (washTypes.length > 0) {
      washTabTitle.classList.remove('is-hidden');
    } else {
      washTabTitle.classList.add('is-hidden');
    }
  };

  handleBrandRendered = (brands) => {
    const tabTitle = this.container.querySelector('[data-tab-title="brand"]');
    if (brands.length > 0) {
      tabTitle.classList.remove('is-hidden');
    } else {
      tabTitle.classList.add('is-hidden');
    }
  };

  handleSizeRendered = (sizes) => {
    const tabTitle = this.container.querySelector('[data-tab-title="size"]');
    const activeSizes = sizes.filter(s => s.isActive);
    if (activeSizes.length > 0) {
      tabTitle.classList.remove('is-hidden');
    } else {
      tabTitle.classList.add('is-hidden');
    }
  };

  handlePriceRendered = (range) => {
    const tabTitle = this.container.querySelector('[data-tab-title="price"]');
    if (range.min === 0 && range.max === 0) {
      tabTitle.classList.add('is-hidden');
    } else {
      tabTitle.classList.remove('is-hidden');
    }
  };

  handleShippingRendered = (shippings) => {
    const tabTitle = this.container.querySelector('[data-tab-title="shipping"]');
    if (shippings.length > 0) {
      tabTitle.classList.remove('is-hidden');
    } else {
      tabTitle.classList.add('is-hidden');
    }
  }

  handleDiscountsRendered = (discounts) => {
    const tabTitle = this.container.querySelector('[data-tab-title="discounts"]');
    if (discounts.length > 0) {
      tabTitle.classList.remove('is-hidden');
    } else {
      tabTitle.classList.add('is-hidden');
    }
  }

  handleDynamicRendered = (filter, items = []) => {
    const tabTitle = this.container.querySelector(`[data-tab-title="${filter}"]`);
    if (items.length > 0) {
      tabTitle.classList.remove('is-hidden');
    } else {
      tabTitle.classList.add('is-hidden');
    }
  }

  handleSubcategoriesRendered = (subcategories) => {
    // const collapsible = this.container.querySelector('[data-filter="subcategories"]');
    // const title = collapsible.querySelector('.MobileTabbed-header-title h3');
    // title.innerHTML = findLabelByLng(
    //   this.main.categories[this.state.filter.categoryId].label,
    //   this.state.config.lng
    // ).replace('Women', '').replace('Men', '');
    // if (subcategories.length > 0) {
    //   collapsible.style.display = 'block';
    // }
    // else {
    //   collapsible.style.display = 'none';
    // }
  };

  handleSVGHide = () => {
    const styleTab = document.querySelector('div[data-tab-title="style"]');
    styleTab.classList.add('is-hidden');
  };

  handleSVGLoaded = () => {
    const styleTab = document.querySelector('div[data-tab-title="style"]');
    styleTab.classList.remove('is-hidden');
  };

  mountStyleWidget() {
    this.main.addWidget(
      this.main.widgets.VisualFilter({
        container: '#mobile-tabbed-visual-filter',
        ...this.params.visualFilterParams,
        onSVGLoaded: (...args) => {
          if (
            typeof this.params.visualFilterParams.onSVGLoaded === 'function'
          ) {
            this.params.visualFilterParams.onSVGLoaded(...args);
          }
          this.handleSVGLoaded();
        },
        onSVGHide: (...args) => {
          if (typeof this.params.visualFilterParams.onSVGHide === 'function') {
            this.params.visualFilterParams.onSVGHide(...args);
          }
          this.handleSVGHide();
        },
        onTouchClick: (...args) => {
          if (
            typeof this.params.visualFilterParams.onTouchClick === 'function'
          ) {
            this.params.visualFilterParams.onTouchClick(...args);
          }
        },
        showResetButton: false,
        onRef: (ref) => {
          this.vmfWidget = ref;
        },
      })
    );

    this.main.addWidget(
      this.main.widgets.StyleFilter({
        container: '#mobile-tabbed-style-filter',
        ...this.params.styleFilterParams,
      })
    );

    if (this.params.usePresetFilter) {
      this.main.addWidget(
        this.main.widgets.EditorsPicks({
          container: '#mobile-tabbed-editors-pick-filter',
          ...this.params.presetsParams,
        })
      );
    }
  }

  mountCategoriesWidget() {
    this.main.addWidget(
      new Categories({
        container: '#mobile-tabbed-categories-filter',
        onCategorySelected: () => {
          this.close();
        },
      })
    );
  }

  mountPriceWidget() {
    this.mountWidget('price');

    this.main.addWidget(
      this.main.widgets.SimplePricesList({
        container: '#mobile-tabbed-price-list-filter',
      })
    );
  }

  mountWidget(filter) {
    const widgetName = WIDGETS_MAP[filter];
    if (widgetName && this.main.widgets[widgetName]) {
      this.main.addWidget(
        this.main.widgets[widgetName]({
          container: `#mobile-tabbed-${filter}-filter`,
          ...this.params[`${filter}FilterParams`],
          ...(filter === 'patterns'
            ? { onRendered: this.handlePatternsRendered }
            : {}),
          ...(filter === 'materials'
            ? { onRendered: this.handleMaterialsRendered }
            : {}),
          ...(filter === 'textMaterials'
            ? { onRendered: this.handleTextMaterialsRendered }
            : {}),
          ...(filter === 'presets'
            ? { onRendered: this.handlePresetsRendered }
            : {}),
          ...(filter === 'occasions'
            ? { onRendered: this.handleOccasionsRendered }
            : {}),
          ...(filter === 'vibes'
            ? { onRendered: this.handleVibesRendered }
            : {}),
          ...(filter === 'wash' ? { onRendered: this.handleWashRendered } : {}),
          ...(filter === 'subcategories'
            ? { onRendered: this.handleSubcategoriesRendered }
            : {}),
          // ...(filter === 'brand' ? { onRendered: this.handleBrandRendered } : {}),
          ...(filter === 'size' ? { onRendered: this.handleSizeRendered } : {}),
          ...(filter === 'price' ? { onRendered: this.handlePriceRendered } : {}),
          ...(filter === 'shipping' ? { onRendered: this.handleShippingRendered } : {}),
          ...(filter === 'discounts' ? { onRendered: this.handleDiscountsRendered } : {}),
          ...(filter.includes('dynamic') ? { onRendered: this.handleDynamicRendered.bind(this, filter) } : {}),
        })
      );
    }
  }

  mountDynamicWidget(filter) {
    const widgetName = WIDGETS_MAP['dynamic'];
    this.main.addWidget(
      this.main.widgets[widgetName]({
        container: `#mobile-tabbed-${filter}-filter`,
        ...this.params[`${filter}FilterParams`],
        onRendered: this.handleDynamicRendered.bind(this, 'filter'),
      })
    );
  }

  handleDynamicRendered = (filter, items = []) => {
    const container = this.container.querySelector(`[data-filter="${filter}"]`);
    if (container) {
      if (items.length > 0) {
        collapsible.style.display = 'block';
      }
      else {
        collapsible.style.display = 'none';
      }
    }
  }

  getStyleFilterValue(_prevFilter, filter) {
    const { parts } = this.main.categories[this.state.filter.categoryId];
    const params = filter.params;
    const values = [];

    parts.forEach((part) => {
      if (params[part.name] && params[part.name] !== 'all') {
        values.push(params[part.name]);
      }
    });

    return values.length > 0 ? `${values.length}` : '';
  }

  getTreeMenuFilterValue(_prevFilter, filter) {
    return filter.params.subcategory && filter.params.subcategory.length
      ? `${filter.params.subcategory.length}`
      : this.startCategoryId !== filter.categoryId
      ? '1'
      : '';
  }

  getOccasionsFilterValue(prevFilter, filter) {
    if (prevFilter.occasion !== filter.occasion) {
      if (!filter.occasion || !filter.occasion?.length) return '';

      return `${filter.occasion.length}`;
    }
    return false;
  }

  getVibesFilterValue(prevFilter, filter) {
    if (prevFilter.params && prevFilter.params.vibe !== filter.params.vibe) {
      if (!filter.params.vibe || !filter.params.vibe?.length) return '';

      return `${filter.params.vibe.length}`;
    }
    return false;
  }

  getMoodsFilterValue(prevFilter, filter) {
    if (prevFilter.mood !== filter.mood) {
      if (!filter.mood || !filter.mood?.length) return '';

      return `${filter.mood?.length}`;
    }
    return false;
  }

  getPatternsFilterValue(prevFilter, filter) {
    const { design: prevPatterns } = prevFilter.params || {};
    const { design: patterns = [] } = filter.params;
    if (!prevPatterns || !isEqual(prevPatterns, patterns)) {
      return patterns.length > 0 ? `${patterns.length}` : '';
    }
    return false;
  }

  getMaterialsFilterValue(prevFilter, filter) {
    const { material: prevMaterials } = prevFilter.params || {};
    const { material: materials = [] } = filter.params;
    if (!prevMaterials || !isEqual(prevMaterials, materials)) {
      return materials.length > 0 ? `${materials.length}` : '';
    }
    return false;
  }

  getColorsFilterValue(prevFilter, filter) {
    const { color: prevColors } = prevFilter.params || {};
    const { color: colors = [] } = filter.params;
    if (!prevColors || !isEqual(prevColors, colors)) {
      return colors.length > 0 ? `${colors.length}` : '';
    }
    return false;
  }

  getPriceFilterValue(prevFilter, filter) {
    const { price: prevPrice } = prevFilter.params || {};
    const { price } = filter.params;
    if (prevPrice !== price) {
      return price ? '1' : '';
      // if (!price || price === '0-') return '';
      // const [low, high] = price.split('-');
      // return [
      //   low ? formatCurrency(parseInt(low), this.params.priceFilterParams.currency || {}) : '',
      //   high ? formatCurrency(parseInt(high), this.params.priceFilterParams.currency || {}) : ' or more',
      // ].join(high ? '-' : '');
    }
    return false;
  }

  getBrandFilterValue(prevFilter, filter) {
    const { brands: prevBrands } = prevFilter.params || {};
    const { brands = [] } = filter.params;
    if (!isEqual(prevBrands, brands)) {
      return brands.length > 0 ? `${brands.length}` : '';
    }
    return false;
  }

  getSortFilterValue(prevFilter, filter) {
    const { sort: prevSort } = prevFilter || {};
    const { sort } = filter;
    if (prevSort !== sort) {
      if (!sort) return '';

      const lng = this.state.config.lng;
      const sortItem = find(this.params.sortFilterParams.items, {
        value: sort,
      });

      return findLabelByLng(sortItem?.label, lng);
    }
    return false;
  }

  getTextMaterialsFilterValue(prevFilter, filter) {
    const { materialText: prevMaterialText } = prevFilter.params || {};
    const { materialText = [] } = filter.params;
    if (!isEqual(prevMaterialText, materialText)) {
      return materialText.length > 0 ? `${materialText.length}` : '';
    }
    return false;
  }

  getSizeFilterValue(prevFilter, filter) {
    const { size: prevSize } = prevFilter.params || {};
    const { size = [] } = filter.params;
    if (!isEqual(prevSize, size)) {
      return size.length > 0 ? `${size.length}` : '';
    }
    return false;
  }

  getDiscountsFilterValue(prevFilter, filter) {
    const { discount: prevDiscount } = prevFilter.params || {};
    const { discount = [] } = filter.params;
    if (!isEqual(prevDiscount, discount)) {
      return discount.length > 0 ? `${discount.length}` : '';
    }
    return false;
  }

  getShippingFilterValue(prevFilter, filter) {
    const { shippingOption: prevShippingOption } = prevFilter.params || {};
    const { shippingOption = [] } = filter.params;
    if (!isEqual(prevShippingOption, shippingOption)) {
      return shippingOption.length > 0 ? `${shippingOption.length}` : '';
    }
    return false;
  }

  getWashFilterValue(prevFilter, filter) {
    const { shippingOption: prevShippingOption } = prevFilter.params || {};
    const { shippingOption = [] } = filter.params;
    if (!isEqual(prevShippingOption, shippingOption)) {
      return shippingOption.length > 0 ? `${shippingOption.length}` : '';
    }
    return false;
  }

  getNewArrivalsFilterValue(prevFilter, filter) {
    const { newArrivals: prevNewArrivals } = prevFilter.params || {};
    const { newArrivals = [] } = filter.params;
    if (!isEqual(prevNewArrivals, newArrivals)) {
      return newArrivals?.length > 0 ? `${newArrivals.length}` : '';
    }
    return false;
  }

  renderFilterValues(prevState = {}) {
    const { filter: prevFilter = {} } = prevState;
    const { filter } = this.state;
    for (const filterName of this.params.filters) {
      if (
        typeof this[`get${upperFirst(filterName)}FilterValue`] === 'function'
      ) {
        const value = this[`get${upperFirst(filterName)}FilterValue`](
          prevFilter,
          filter
        );
        if (typeof value === 'string') {
          const titleElement = document.getElementById(
            `${filterName}-filter-value`
          );
          if (titleElement)
            titleElement.innerHTML = value.length ? `${value}` : value;
        }
      }
    }
  }

  getStyleFilterAction() {
    const { lng } = this.state.config;
    return findLabelByLng(this.params.styleFilterActionLabel, lng);
  }

  handleStyleFilterActionClick = (e) => {
    e.stopPropagation();
    const filter = this.main.getFilter();
    const { parts } = this.main.categories[filter.categoryId];
    const params = filter.params;

    parts.forEach((part) => {
      params[part.name] = 'all';

      if (!part.isPublished) params[part.name] = part.disabledValue;
    });
    this.setFilter({
      presetIndex: null,
      occasion: null,
      params,
    });

    EventEmitter.emit('collapsibleBodyPartsClearClicked');
  };

  switchBack() {
    const activeTabTitle = this.tabTitles.querySelectorAll(`.is-active`);
    activeTabTitle.forEach((tabTitle) => {
      tabTitle.classList.remove('is-active');
    });

    const activeTab = this.tabs.querySelectorAll(`.is-active`);
    activeTab.forEach((tab) => {
      tab.classList.remove('is-active');
    });

    this.activeTab = null;

    this.mainElement.classList.remove('is-filter-selected');
  }

  switchTab(filter) {
    const activeTabTitle = this.tabTitles.querySelectorAll(`.is-active`);
    activeTabTitle.forEach((tabTitle) => {
      tabTitle.classList.remove('is-active');
    });
    const tabTitle = this.tabTitles.querySelector(
      `[data-tab-title="${filter}"]`
    );
    tabTitle.classList.add('is-active');

    const activeTab = this.tabs.querySelectorAll(`.is-active`);
    activeTab.forEach((tab) => {
      tab.classList.remove('is-active');
    });
    const tab = this.tabs.querySelector(`[data-filter="${filter}"]`);
    tab.classList.add('is-active');

    this.activeTab = filter;

    if (filter === 'style' && this.vmfWidget) {
      this.vmfWidget.redrawDynamicElements();
    }

    this.mainElement.classList.add('is-filter-selected');
  }

  renderTabTitle(filter) {
    const { lng } = this.state.config;
    const tabTitle = document.createElement('div');
    tabTitle.className = `MobileTabbed-title`;
    tabTitle.setAttribute('data-tab-title', filter);
    tabTitle.innerHTML = `
      <h4>
        <span class="tab-title-text">${findLabelByLng(
          this.params.filterLabels[filter],
          lng
        )}</span>
        <span class="filter-value" id="${filter}-filter-value"></span>
        <span><img src="${arrowRightSvg}" alt="" /></span>
      </h4>
    `;

    tabTitle.addEventListener('click', () => this.switchTab(filter));

    return tabTitle;
  }

  renderTab(filter) {
    const { lng } = this.state.config;
    let htmlContent = `<div id="mobile-tabbed-${filter}-filter"></div>`;
    if (filter === 'style') {
      htmlContent =
        '<div id="mobile-tabbed-visual-filter"></div>' + htmlContent;

      if (this.params.usePresetFilter) {
        htmlContent =
          '<div id="mobile-tabbed-editors-pick-filter"></div>' + htmlContent;
      }

      htmlContent = '<h3>Tap to begin a search</h3>' + htmlContent;
    }
    if (filter === 'price') {
      htmlContent =
        htmlContent + '<div id="mobile-tabbed-price-list-filter"></div>';
    }

    const tab = document.createElement('div');
    tab.className = `MobileTabbed`;
    tab.setAttribute('data-filter', filter);

    htmlContent =
      `<div class="MobileTabbed-header">
      <button class="MobileTabbed-header-back">
        <img src="${arrowLeftSvg}" alt="" />
      </button>
      <h2>${findLabelByLng(this.params.filterLabels[filter], lng)}</h2>
      <div class="MobileTabbed-header-actions">
        <button class="MobileTabbed-header-action" data-clear-filter="${filter}">Clear</button>
      </div>
    </div><div class="MobileTabbed-filter-content">` +
      htmlContent +
      `</div>`;

    tab.innerHTML = htmlContent;

    tab
      .querySelector('.MobileTabbed-header-back')
      .addEventListener('click', () => this.switchBack());

    return tab;
  }

  open() {
    const slider = this.mainElement;
    slider.style.transform = `translate3d(0, 0, 0)`;

    if (typeof this.params.onOpen === 'function') {
      this.params.onOpen();
    }

    const onOpened = () => {
      document.body.style.overflow = 'hidden';
      slider.removeEventListener('transitionend', onOpened);

      EventEmitter.emit('collapsibleMobileOpened');

      if (typeof this.params.onOpened === 'function') {
        this.params.onOpened();
      }
    };
    slider.addEventListener('transitionend', onOpened);
  }

  close() {
    const slider = this.mainElement;

    slider.style.transform = `translate3d(0, calc(var(--vh, 1vh) * 120), 0)`;

    if (typeof this.params.onClose === 'function') {
      this.params.onClose();
    }

    const onClosed = () => {
      document.body.style.overflow = 'visible';
      slider.removeEventListener('transitionend', onClosed);

      EventEmitter.emit('collapsibleMobileClosed');

      if (typeof this.params.onClosed === 'function') {
        this.params.onClosed();
      }
    };
    slider.addEventListener('transitionend', onClosed);
  }

  render() {
    const { lng } = this.state.config;

    this.mainElement.classList.add('MobileTabbedFilters');
    for (const filter of this.params.filters) {
      if (!filter) continue;
      this.tabs.appendChild(this.renderTab(filter));
      this.tabTitles.appendChild(this.renderTabTitle(filter));
    }

    if (this.params.isFoldable) {
      this.mainElement.classList.add('MobileTabbedFilters-Foldable');
    }

    if (this.params.useHeader) {
      this.mainElement.insertAdjacentHTML(
        'afterbegin',
        `
        <div class="MobileTabbedFilters-header">
          <div id="mobile-tabbed-close-button" class="MobileTabbedFilters-header-close">
            <img src="${closeIcon}" alt="" />
          </div>
          <div class="MobileTabbedFilters-header-actions">
            <h3>${findLabelByLng(this.params.title, lng)}</h3>
            <button id="mobile-tabbed-header-clear-button" class="MobileTabbedFilters-header-action">${findLabelByLng(
              this.params.headerClearAllText,
              lng
            )}</button>
          </div>
        </div>
      `
      );
    }

    if (this.params.useFooter) {
      this.mainElement.insertAdjacentHTML(
        'beforeend',
        `
        <div class="MobileTabbedFilters-footer">
          <div id="mobile-tabbed-chips" class="MobileTabbedFilters-footer-chips"></div>
          <div class="MobileTabbedFilters-footer-button-container">
            <button id="mobile-tabbed-apply-button" class="MobileTabbedFilters-footer-button">
              ${findLabelByLng(
                this.params.showResultButtonLeft,
                lng
              )}<span id="mobile-tabbed-total-found"></span>${findLabelByLng(
          this.params.showResultButtonRight,
          lng
        )}
              ${this.params.showResultButtonSvg}
            </button>
          </div>
        </div>
      `
      );
    }

    if (!this.params.isOpenInitialy && this.params.isFoldable) {
      this.mainElement.style.transform = `translate3d(0, 100vh, 0)`;
    }

    this.mainElement.setAttribute(
      'data-category-id',
      this.state.filter.categoryId
    );

    return this.mainElement;
  }
}

export default (params) => {
  return new MobileTabbedFilters(params);
};
