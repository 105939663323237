import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { USE_TOOLTIP } from 'config/consts';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import PublishIcon from '@material-ui/icons/Publish';
import { useSelector } from 'react-redux';
import TreeCategoriesMenu from './TreeCategoriesMenu';
import LngSelector from './LngSelector';
import RetailerSelector from 'components/RetailerSelector'
import styles from './HeaderMenu.module.scss';
import classNames from 'classnames';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function Alert({ onClose, open, title, message }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function HeaderMenu({ categories, onTooltipClick, isTooltipActive, onPreviewClick, isMobile, onStatsClick, isStatsActive }) {
  const { retailer, lngs, isAdmin } = useSelector(({ AdminAuth: { userInfo: { retailer, lngs, isAdmin } } }) => ({ retailer, lngs, isAdmin }));
  const location = useLocation();
  const [alertData, setAlertData] = useState(null);
  const isEditorPage = /^\/visual-filter-editor/.test(location.pathname);

  const handleScheduleClick = async () => {
    console.log('handleScheduleClick');
    try {
      await axios({
        method: 'get',
        url: `/retailers/${retailer}/schedule-deployment`,
      });
      setAlertData({
        title: 'Schedule to deploy',
        message: 'Deployment was scheduled successfully',
      });
    } catch (e) {
      setAlertData({
        title: 'Schedule to deploy error',
        message: 'Error while scheduling deployment',
      });
    }
  }

  return ReactDOM.createPortal(
    <>
      <Alert
        onClose={() => setAlertData(null)}
        open={!!alertData}
        title={alertData?.title}
        message={alertData?.message}
      />
      <div className={styles['categories-menu']}>
        {retailer === 'handsome' || retailer === 'wconcept' && <TreeCategoriesMenu categories={categories} />
        }
      </div>
      {isAdmin && <div>
        <RetailerSelector />
      </div>}
      {isAdmin && <div>
        <NavLink
          exact
          to={isEditorPage ? '/visual-filter' : '/visual-filter-editor'}
          // onClick={this.handleLinkClick}
          className="logout-button"
        >
          Go to {isEditorPage ? 'Preview' : 'Editor'}
        </NavLink>
      </div>}
      {!isEditorPage && <div className={styles['preview-buttons']}>
        <button className={classNames(styles['preview-button'], { [styles['active']]: isMobile })} onClick={onPreviewClick}>
          <MobileFriendlyIcon />
        </button>
        <button className={classNames(styles['preview-button'], { [styles['active']]: !isMobile })} onClick={onPreviewClick}>
          <DesktopWindowsIcon />
        </button>
      </div>}
      {isEditorPage && <div>
        <button className={classNames(styles['stats'], { [styles['is-active']]: isStatsActive })} onClick={onStatsClick}>
          <EqualizerIcon />
        </button>
      </div>}
      {USE_TOOLTIP && <div>
        <button className={styles['tooltip']} onClick={onTooltipClick}>
          {
            isTooltipActive
              ? <SpeakerNotesIcon />
              : <SpeakerNotesOffIcon />
          }
        </button>
      </div>}
      {isEditorPage && <div>
        <button title='Schedule to publish updates' className={styles['stats']} onClick={handleScheduleClick}>
          <PublishIcon />
        </button>
      </div>}
      {lngs.length > 1 && <div className={styles['lng-selector']}>
        <LngSelector />
      </div>}
    </>,
    document.getElementById('header-portal')
  );
}
