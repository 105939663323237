import client from '@yesplz/client';
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import qs from 'qs';
import * as widgets from './widgets';
import EventEmitter from './modules/EventEmitter';
import './modules/analytics';

const searchStateParams = ['query', 'categories', 'topCategory', 'sort', 'sale', 'new', 'bestsellers', 'limit', 'offset', 'price', 'brands', 'colors', 'materials', 'sizes', 'discount', 'shipping', 'newArrivals', 'cfo'];
const listParams = ['categories', 'brands', 'colors', 'materials', 'sizes', 'discount', 'shipping', 'cfo'];

function parseBoolean(val) {
  if (val === 'false' || val === 'true') {
    return val === 'true'
  }
  return  val;
}

function parseObject(obj) {
  var result = {};
  var key, val;
  for (key in obj) {
    val = parseBoolean(obj[key]);
    if (val !== null) result[key] = val; // ignore null values
  }
  return result;
}

export default class TextSearch {
  widgetsContainer = [];

  constructor(params) {
    const { initialFilter, useSearchQuery = true } = params;

    this.widgets = { ...widgets };

    this.state = {
      search: {},
      config: { lng: 'en' },
    };
    this.state.filter = {
      ...initialFilter,
      ...(useSearchQuery ? this.searchQuery : {}),
    };

    if (useSearchQuery) {
      window.addEventListener('popstate', () => {
        this.updateSearchQuery({
          ...(
            searchStateParams.reduce((updates, param) => {
              updates[param] = null;
              return updates;
            }, {})
          ),
          ...this.searchQuery,
        });
      });
    }

    window.requestAnimationFrame(() => {
      if (!isEmpty(this.state.filter) && this.state.filter.query) {
        this.searchWithCurrentFilter();
      }
    });

    this.useSearchQuery = useSearchQuery;

    this.searchPromise = null;
    this.previewPromise = null;

    this.currentSearch = null;

    if (params.clientBaseURL) {
      client.setBaseURL(params.clientBaseURL);
    }
    if (params.clientBaseURLToken) {
      client.setToken(params.clientBaseURLToken);
    }
    if (params.clientSearchPath) {
      client.setSearchPath(params.clientSearchPath);
    }
    if (params.clientAliases) {
      client.setAliases(params.clientAliases);
    }

    this.ignoredParams = [];
  }

  addIgnoredParams(params) {
    this.ignoredParams = this.ignoredParams.concat(params);
  }

  get searchQuery() {
    const searchQuery = pick(
      qs.parse(window.location.search, { ignoreQueryPrefix: true, comma: true }),
      searchStateParams
    );
    if (searchQuery['price'] && /^[0-9.]*-[0-9.]*$/.test(searchQuery['price'])) {
      let priceText = searchQuery['price'];
      if (priceText.indexOf('-') === 0) {
        priceText = priceText.slice(1);
      }
      const prices = priceText.split('-');
      const from = parseInt(prices[0], 10);
      const to = parseInt(prices[1], 10);
      
      searchQuery['price'] = `${!isNaN(from) && from && from > 0 ? from : ''}-${!isNaN(to) && to && to > 0 ? to : ''}`;
    }
    else {
      delete searchQuery['price'];
    }
    listParams.forEach(name => {
      if (searchQuery[name] && typeof searchQuery[name] === 'string') {
        searchQuery[name] = searchQuery[name].split(',');
      }
      else if (!isEmpty(searchQuery) && !searchQuery[name]) {
        searchQuery[name] = [];
      }
    });

    return parseObject(searchQuery);
  }

  addWidget(widget) {
    this.mountWidget(widget);
  }

  mountWidget = widget => {
    this.widgetsContainer.push(widget);
    widget.main = this;
    widget.mount(this.state);
  }

  notifyWidgets(prevState) {
    this.widgetsContainer.forEach(widget => {
      widget.updateInternalStateValue(this.state, prevState);
    });
  }

  updateSearchQuery(updates) {
    if (updates?.price === '-') {
      updates.price = null;
    }
    const filter = {
      ...this.state.filter,
      offset: 0,
      ...updates,
    };
    const search = qs.stringify(filter, { skipNulls: true, arrayFormat: 'comma' });

    if (this.useSearchQuery) {
      const url = window.location.origin + window.location.pathname + `?${search}`;
      window.history.replaceState({}, "", url);
    }

    this.setState({ filter });
  }

  setState(stateUpdates) {
    const prevState = cloneDeep(this.state);
    const newState = {
      ...cloneDeep(this.state),
      ...cloneDeep(stateUpdates),
    };

    this.state = newState;
    this.notifyWidgets(prevState);

    if (!isEmpty(this.state.filter) && !isEqual(this.state.filter, prevState.filter) && this.state.filter.query) {
      this.searchWithCurrentFilter();
    }
  }

  setSort(sort) {
    this.updateSearchQuery({ sort });
  }

  setLimit(limit) {
    this.updateSearchQuery({ limit });
  }

  setOffset(offset) {
    this.updateSearchQuery({ offset });
  }

  setPrice(price) {
    this.updateSearchQuery({ price });
  }

  toggleBrand(brand) {
    this.toggleList('brands', brand);
  }

  toggleValue(name, value) {
    const newValue = this.state.filter[name] === value ? null : value;
    this.updateSearchQuery({ [name]: newValue });
  }

  toggleList(name, value) {
    let values = this.state.filter[name] || [];
    values = values.includes(value)
      ? values.filter(v => v !== value)
      : [...values, value];

    this.updateSearchQuery({ [name]: values });
  }

  resetList(name) {
    this.updateSearchQuery({ [name]: [] });
  }

  fetchBrandsSuggestions(query, count) {
    return client.textBrands({ query, count });
  }

  fetchPopularQueries(topCategory, sale) {
    return client.textPopularQueries({ topCategory, sale });
  }

  fetchTextAutocomplete(query, topCategory, sale) {
    return client.textAutocomplete({ query, topCategory, sale });
  }

  fetchTextSearch({ limit = 72, offset = 0, sale, ...params }) {
    return client.textSearch({
      limit, offset,
      sale: sale ? 'saleonly' : 'all',
      ...params,
    });
  }

  searchWithCurrentFilter() {
    let params = {
      ...cloneDeep(this.state.filter),
    };
    if (typeof params.categories === 'string') {
      params.categories = params.categories.split(',');
    }
    if (params.gender) {
      if (params.gender === 'women') {
        params.categories.push('Women');
      }
      else if (params.gender === 'men') {
        params.categories.push('Men');
      }
      delete params.gender;
    }
    if (params.sale) {
      params.sale = true;
    }
    else {
      params.sale = false;
    }
    if (params.new) {
      params.categories.push('New');
    }
    if (params.bestsellers) {
      params.categories.push('Top Sellers');
    }
    delete params.new;
    delete params.bestsellers;

    if (!isEmpty(this.ignoredParams)) {
      params = omit(params, this.ignoredParams);
    }

    if (isEqual(params, this.currentSearch)) return;

    EventEmitter.emit('searchStarted', params);

    this.setState({ search: {
      ...this.state.search,
      isLoading: true,
      error: null,
    } });

    const previewPromise = this.fetchTextSearch({
      ...params,
      preview: true,
    });
    this.previewPromise = previewPromise;
    const searchPromise = this.fetchTextSearch(params);
    let isSearchPromiseResolved = false;
    this.searchPromise = searchPromise;
    previewPromise
      .then(data => {
        if (this.previewPromise === previewPromise && !isSearchPromiseResolved) {
          EventEmitter.emit('searchFinished', data);
          this.setState({search: {
            ...this.state.search,
            ...data,
            isLoading: false,
            error: null,
          }});
        }
      })
      .catch(() => {});

    this.currentSearch = params;
    searchPromise
      .then(data => {
        if (this.searchPromise === searchPromise) {
          this.setState({search: {
            ...this.state.search,
            ...data,
            isLoading: false,
            error: null,
          }});
          isSearchPromiseResolved = true;
        }
      })
      .catch(error => {
        this.setState({search: {
          ...this.state.search,
          isLoading: false,
          error: 'Failed search request!',
        }});
        console.error('text search error', error);
      });

    return searchPromise;
  }

  on(event, subscriber) {
    EventEmitter.on(event, subscriber);
  }

  off(event, subscriber) {
    EventEmitter.off(event, subscriber);
  }
}
