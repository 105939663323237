import isEqual from 'lodash/isEqual';
import Widget from '../modules/Widget';
import noUiSlider from 'nouislider';
import { formatCurrency, findLabelByLng } from '@yesplz/core';

const { document } = window;

const TEMPLATE = `
<div class="yesplz-price-range-container">
  <div id="yesplz-price-range-title" class="yesplz-price-range-title hidden"></div>
  <div id="yesplz-price-range-slider" class="yesplz-price-range-slider"></div>
  <div class="yesplz-price-range-values">
    <span id="yesplz-price-range-value-low"></span>
    <span id="yesplz-price-range-value-high"></span>
  </div>
</div>
`;

class PriceRangeFilter extends Widget {
  defaultParams = {
    title: 'Price',
    searchButtonText: 'Search',
    step: null,
    currency: {},
    overMessage: [{ lng: 'en', label: 'Over {price}' }, { lng: 'ko', label: '{price} 이상' }],
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className = this.params.containerClassName || 'yesplz-price-range-filter';
    this.mainElement = element;

    this.pricesByCategories = {};

    this.mainElement.innerHTML = TEMPLATE;

    this.slider = null;

    this.prices = {};
  }

  didMount() {
    this.getAndRenderPrices();
  }

  didUpdate(prevState) {
    if (!isEqual(prevState.search?.filter?.salePrice, this.state.search?.filter?.salePrice)) {
      this.getAndRenderPrices();
    }
    else if (
      !isEqual(prevState.filter.price, this.state.filter.price)
    ) {
      this.renderLabels(...this.values);
      if (this.slider.noUiSlider) {
        const [low, high] = this.values;
        this.slider.noUiSlider.updateOptions({
          start: [low || this.range.min, high || this.range.max],
        });
      }
    }
  }

  formatCurrency(number) {
    return formatCurrency(number,  this.params.currency);
  }

  floorToTen(number) {
    return Math.floor(number / 10) * 10;
  }

  ceilToTen(number) {
    return Math.ceil(number / 10) * 10;
  }

  get range() {
    if (typeof this.prices.minPrice === 'number' && typeof this.prices.maxPrice === 'number') {
      return {
        min: this.floorToTen(this.prices.minPrice),
        max: this.ceilToTen(this.prices.overPrice && this.prices.maxPrice && this.prices.maxPrice < this.prices.overPrice ? this.prices.maxPrice : (this.prices.overPrice || this.prices.maxPrice)),
      };
    }
    else {
      const prices = Object.keys(this.prices);
      const min = parseInt(prices[0].replace('$', ''));
      const max = parseInt(prices[prices.length - 1].replace('$', ''));
      return { min, max };
    }
  }

  get step() {
    if (this.params.step) {
      return this.params.step;
    }

    if (this.prices.step) {
      return this.prices.step;
    }

    const prices = Object.values(this.prices);
    if (prices[0].includes('-'))
      return parseInt(prices[0].split('-')[1]);
    else
      return prices[1] - prices[0];
  }

  get values() {
    const price = this.state.filter.price;

    if (!price) return [];

    const [low, high] = price.split('-');

    return [low, high || this.range.max];
  }

  handlePriceChange = (low, high) => {
    this.main.setPrice(`${low}-${high < this.range.max ? high : ''}`);
  }

  handlePriceUpdate = (low, high) => {
    this.renderLabels(low, high);
  }

  getAndRenderPrices() {
    if (!this.state.search?.filter?.salePrice) return;

    const { salePrice } = this.state.search?.filter;
    this.prices = salePrice;
    this.renderPrices();
  }

  renderLabels(low, high) {
    const { lng } = this.state.config;
    const lowElement = document.getElementById('yesplz-price-range-value-low');
    const highElement = document.getElementById('yesplz-price-range-value-high');
    const lowText = `${this.formatCurrency(low || this.range.min || 1)}`;
    const highText = (
      high && high !== this.range.max
        ? `${this.formatCurrency(high)}`
        : findLabelByLng(this.params.overMessage, lng)
            .replace('{price}', this.formatCurrency(this.range.max || 100))
    );
    
    lowElement.innerHTML = lowText;
    highElement.innerHTML = highText;

    const title = document.getElementById('yesplz-price-range-title');
    title.classList.remove('hidden');
    title.innerHTML = `(${lowText} ~ ${highText})`;
  }

  renderPrices() {
    const [low, high] = this.values;

    const slider = document.getElementById('yesplz-price-range-slider');

    if (slider.noUiSlider) {
      slider.noUiSlider.destroy();
    }

    noUiSlider.create(slider, {
      start: [low || this.range.min, high || this.range.max],
      step: this.step,
      connect: true,
      margin: this.step,
      range: this.range,
    });

    slider.noUiSlider.on('change', ([lowStr, highStr]) => {
      this.handlePriceChange(parseInt(lowStr), parseInt(highStr));
    });

    slider.noUiSlider.on('update', ([lowStr, highStr]) => {
      this.handlePriceUpdate(parseInt(lowStr), parseInt(highStr));
    });

    this.renderLabels(...this.values);

    this.slider = slider;

    if (typeof this.params.onRendered === 'function')
      this.params.onRendered(this.range);
  }

  render() {
    const title = document.createElement('h3');
    title.innerText = this.params.title;
    this.container.appendChild(title);
    return this.mainElement;
  }
}

export default (params) => {
  return new PriceRangeFilter(params);
};
