import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import findIndex from 'lodash/findIndex';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';
import isParamsMatchPreset from '../helpers/isParamsMatchPreset';
import { CATEGORY_WSHOES, CATEGORY_WPANTS, CATEGORY_MPANTS } from '../consts';

import { findLabelByLng } from '@yesplz/core';

import designSolidSvg from '@yesplz/core-models/assets/svg/design-solid.svg';
import designPatternSvg from '@yesplz/core-models/assets/svg/design-pattern.svg';
import designDetailSvg from '@yesplz/core-models/assets/svg/design-detail.svg';
import designRippedOffSvg from '@yesplz/core-models/assets/svg/design-ripped-off.svg';
import designLaceUpSvg from '@yesplz/core-models/assets/svg/design-lace-up.svg';
import designLightSvg from '@yesplz/core-models/assets/svg/design-light.svg';
import designLeatherSvg from '@yesplz/core-models/assets/svg/design-leather.svg';
import designDenimSvg from '@yesplz/core-models/assets/svg/design-denim.svg';
import designSlacksSvg from '@yesplz/core-models/assets/svg/design-slacks.svg';
import designWorkoutSvg from '@yesplz/core-models/assets/svg/design-workout.svg';
import designLeggingsSvg from '@yesplz/core-models/assets/svg/design-leggings.svg';
import designSuedeSvg from '@yesplz/core-models/assets/svg/design-suede.svg';
import designWoolSvg from '@yesplz/core-models/assets/svg/design-wool.svg';
import designDownSvg from '@yesplz/core-models/assets/svg/design-down.svg';
import designAnimalSvg from '@yesplz/core-models/assets/svg/design-animal.png';
import designCottonSvg from '@yesplz/core-models/assets/svg/design-cotton.svg';

import resetIconSvg from '@yesplz/core-models/assets/svg/reset-icon.svg';

const defaultDesignTypes = [
  {type: 'solid', label: 'Solid', image: designSolidSvg, remove: 'pattern'},
  {type: 'pattern', label: 'Pattern', image: designPatternSvg, remove: 'solid'},
  {type: 'detail', label: 'Detail', image: designDetailSvg, disabledIn: [CATEGORY_MPANTS]},
  {type: 'ripped-off', label: 'Ripped Off', image: designRippedOffSvg, activeIn: [CATEGORY_WPANTS, CATEGORY_MPANTS]},
  {type: 'lace-up', label: 'Lace Up', image: designLaceUpSvg, activeIn: [CATEGORY_WSHOES]},
  {type: 'light', label: 'Light', image: designLightSvg, activeIn: ['wouter', 'mouter']},
  {type: 'leather', label: 'Leather', image: designLeatherSvg, activeIn: ['wouter', 'wshoes', 'mouter', 'mshoes', 'wwallet', 'mwallet']},
  {type: 'denim', label: 'Denim', image: designDenimSvg, activeIn: ['wpants', 'mpants']},
  {type: 'cotton', label: 'Cotton', image: designCottonSvg, activeIn: ['wpants']},
  {type: 'wool', label: 'Wool', image: designWoolSvg, activeIn: ['wpants', 'mpants']},
  {type: 'down', label: 'Down', image: designDownSvg, activeIn: ['wouter', 'mouter']},
  {type: 'workout', label: 'Workout', image: designWorkoutSvg, activeIn: ['wpants', 'mpants']},
  {type: 'leggings', label: 'Leggings', image: designLeggingsSvg, activeIn: ['wpants']},
  {type: 'suede', label: 'Suede', image: designSuedeSvg, activeIn: ['wshoes', 'mshoes']},
  {type: 'animal', label: 'Animal Print', image: designAnimalSvg, activeIn: ['wwallet', 'mwallet']},
  {type: 'fabric', label: 'Fabric', image: designSlacksSvg, activeIn: ['wwallet', 'mwallet']},
];

class DesignFilter extends Widget {
  defaultParams = {
    showReset : true,
    resetImage: resetIconSvg,
    resetLabel: [{ "lng": "en", "label": "Reset" }, { "lng": "ko", "label": "초기화" }],
  };

  constructor(params) {
    super(params);

    this.element = document.createElement('div');
    this.element.className = 'ThumbnailPicker';
    this.designElements = {};

    if (!this.params.designTypes) {
      this.params.designTypes = defaultDesignTypes;
    }

    this.designTypes = this.params.designTypes.reduce((patterns, pattern) => {
      patterns[pattern.type] = pattern;
      return patterns;
    }, {});
  }

  handleLabelClick = (type, remove) => {
    const { categoryId, params } = this.state.filter;

    EventEmitter.emit('patternThumbnailClick', {
      categoryId,
      design: type,
    });

    let design = params.design ? [...params.design] : [];
    if (type === 'reset') {
      design = [];

      EventEmitter.emit('patternsReset', {
        categoryId,
        design: 'reset',
      });
    }
    else if (design.includes(type)) {
      design.splice(design.indexOf(type), 1);

      EventEmitter.emit('patternRemoved', {
        categoryId,
        design: type,
      });
    }
    else {
      design.push(type);

      EventEmitter.emit('patternApplied', {
        categoryId,
        design: type,
      });
    }

    if (remove && design.includes(remove)) {
      design.splice(design.indexOf(remove), 1);
    }

    const newParams = {
      ...params,
      design,
    };
    
    const currentPresetIndex = this.state.filter.presetIndex;
    let presetIndex = null;
    if (currentPresetIndex) {
      const preset = this.main.categories[this.state.filter.categoryId].presetsList[currentPresetIndex];
      if (preset && isParamsMatchPreset(newParams, preset)) {
        presetIndex = currentPresetIndex;
      }
    }

    this.setFilter({
      presetIndex,
      params: newParams,
    });
  }

  didUpdate(prevState) {
    if (
      prevState.filter.categoryId !== this.state.filter.categoryId
      ||
      !isEqual(
        prevState.search?.filters?.patterns,
        this.state.search?.filters?.patterns
      )
    ) {
      this.renderDesigns();
    }
    else if (
      !isEqual(prevState.filter.params.design, this.state.filter.params.design)
    ) {
      const prevDesign = prevState.filter.params.design;
      const design = this.state.filter.params.design;
      const addedItems = difference(design, prevDesign);
      const removedItems = difference(prevDesign, design);
      addedItems.forEach(type => {
        if (this.designElements[type])
          this.designElements[type].classList.add('is-active')
      });
      removedItems.forEach(type => {
        if (this.designElements[type])
          this.designElements[type].classList.remove('is-active');
      });
    }
  }

  get patterns() {
    const { categoryId } = this.state.filter;
    const category = this.main.categories[categoryId];

    let patterns = (category.patterns || []).map(pattern => {
      return {
        ...this.designTypes[pattern.value || pattern.type],
        ...pattern,
        isActive: false,
      };
    });

    if (this.state.search?.filters?.patterns) {
      Object.values(this.state.search?.filters?.patterns)
        .forEach(({ value, isActive }) => {
          if (isActive) {
            const index = findIndex(patterns, { type: value });
            if (index > -1) {
              patterns[index].isActive = true;
            }
          }
        });
    }
    else {
      patterns = patterns.map(p => ({ ...p, isActive: true }));
    }

    return patterns;
  }

  renderDesigns() {
    const { lng } = this.state.config;
    const { params } = this.state.filter;
    this.element.innerHTML = '';
    this.designElements = {};

    const filteredTypes = this.patterns;
    [
      ...filteredTypes,
      ...(
        this.params.showReset && filteredTypes.length
          ? [{ type: 'reset', label: findLabelByLng(this.params.resetLabel, lng), image: this.params.resetImage, className: 'reset', isActive: true }]
          : []
      ),
    ].forEach((design) => {
      const labelElement = document.createElement('div');
      labelElement.innerHTML = `
        <div class="ThumbnailPickerOption-thumbnail">
          <div class="ThumbnailPickerOption-imageWrapper">
            <img src="${design.image}" alt="" />
          </div>
        </div>
        <h5><span>${findLabelByLng(design.label, lng)}</span></h5>
      `;
      labelElement.className = 'ThumbnailPickerOption' + (
        params.design && params.design.includes(design.type) ? ' is-active' : ''
      ) + (
        !design.isActive ? ' is-disabled' : ''
      ) + (design.className ? ` ${design.className}` : ` pattern-${design.type}`);
      this.designElements[design.type] = labelElement;
      this.element.appendChild(labelElement);

      if (design.isActive) {
        labelElement.addEventListener('click', () => {
          this.handleLabelClick(design.type, design.remove);
        });
      }
    });

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(filteredTypes);
    }
  }

  render() {
    const container = document.createElement('div');
    const title = document.createElement('h3');
    title.innerText = this.params.title ? this.params.title : 'Design';
    container.appendChild(title);
    this.renderDesigns();
    container.appendChild(this.element);
    return container;
  }
}

export default (params) => {
  return new DesignFilter(params);
};
