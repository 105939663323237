import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import { findLabelByLng } from '@yesplz/core';
import Widget from '@yesplz/visualfilter/src/modules/Widget';

const CONTAINER_CLASS_NAME = `yesplz-brand-filter`;

class SubcategoriesList extends Widget {
  defaultParams = {
    allItemLabel: null,
  }

  constructor(params) {
    super(params);

    this.element = document.createElement('div');
    this.subcategories = [];
    this.labelElements = {};
  }

  handleLabelClick = (e) => {
    const button = e.target.parentElement;
    const newSubcategory = button.getAttribute('data-subcategory');
    const { subcategory: currentSubcategory } = this.state.filter.params;

    let subcategory = [...(currentSubcategory || [])];

    if (newSubcategory === 'all') {
      subcategory = [];
    }
    else if (subcategory.includes(newSubcategory)) {
      subcategory = subcategory.filter(c => c !== newSubcategory);
    }
    else {
      subcategory.push(newSubcategory);
    }

    this.setFilter({
      params: {
        ...this.state.filter.params,
        subcategory,
      },
    });

    if (typeof this.params.onItemClick === 'function') {
      this.params.onItemClick(newSubcategory);
    }
  }

  didUpdate(prevState) {
    if (this.state.filter.categoryId !== prevState.filter.categoryId) {
      this.renderItems();
    }
    else if (!isEqual(this.state.filter.params.subcategory, prevState.filter.params.subcategory)) {
      const subcategory = this.state.filter.params.subcategory;
      const prevSubcategory = prevState.filter.params.subcategory;

      const selected = difference(subcategory, prevSubcategory);
      const deselected = difference(prevSubcategory, subcategory);

      selected.forEach(subcategory => {
        const button = this.element.querySelector(`[data-subcategory="${subcategory}"]`);
        if (button) button.classList.add('is-selected');
      });
      deselected.forEach(subcategory => {
        const button = this.element.querySelector(`[data-subcategory="${subcategory}"]`)
        if (button) button.classList.remove('is-selected');
      });

      const allButton = this.element.querySelector(`[data-subcategory="all"]`);
      if (!subcategory || subcategory?.length === 0 && allButton) {
        allButton.classList.add('is-selected');
      }
      else if (allButton) {
        allButton.classList.remove('is-selected');
      }
    }
  }

  renderItems() {
    const { lng } = this.state.config;
    const { categoryId, params: { subcategory } } = this.state.filter;
    const categorySettings = this.main.categories[categoryId];

    if (!categorySettings) return;

    this.element.className = CONTAINER_CLASS_NAME;
    this.element.innerHTML = '';
    this.subcategories = categorySettings.subcategories || [];

    const items = [
      ...(this.params.allItemLabel ? [{
        label: this.params.allItemLabel,
        value: 'all',
      }] : []),
      ...this.subcategories,
    ];

    const presetsHtml = items.map(({ label, value }) => {
      return `
        <li data-subcategory="${value}"${
          (subcategory && subcategory.includes(value)) ||
          ((!subcategory || subcategory?.length === 0) && value === 'all')
            ? ' class="is-selected"'
            : ''
        }>
          <span>${findLabelByLng(label, lng)}</span>
        </li>
      `;
    });

    const html = `
      <ul>
        ${presetsHtml.join('')}
      </ul>
    `;

    this.element.insertAdjacentHTML('beforeend', html);

    this.element.querySelectorAll('[data-subcategory] > span').forEach(button => {
      button.addEventListener('click', this.handleLabelClick);
    });

    if (typeof this.params.onRendered === 'function') {
      this.params.onRendered(this.subcategories);
    }
  }

  render() {
    this.renderItems();
    return this.element;
  }
}

export default (params) => {
  return new SubcategoriesList(params);
};
