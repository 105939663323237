import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Loading from 'components/Loading';
import Page from './Page';

export default () => {
  const [configuration, setConfiguration] = useState(null);
  const AdminAuth = useSelector(({ AdminAuth }) => AdminAuth);
  const { userInfo } = AdminAuth;
  const { retailer } = userInfo;

  async function getConfiguration() {
    const reponse = await axios({
      method: 'get',
      url: `/retailers/${retailer}/configuration`,
    });
    setConfiguration(reponse.data);
  }

  useEffect(() => {
    getConfiguration();
  }, [retailer]);

  if (!configuration) return <Loading />;

  return (
    <Page retailer={retailer} configuration={configuration} />
  );
}
