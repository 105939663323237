import "core-js/stable";
import "regenerator-runtime/runtime";
import './modules/analytics';
import './styles/styles.scss';
import VisualFilter from './Main';
import { categories, extendedCategories, patternsFilterParams, materialsFilterParams, fetchConfiguration } from './extended-configuration';

export default (params) => (new VisualFilter(params));

export {
  VisualFilter,
  categories,
  extendedCategories,
  patternsFilterParams,
  materialsFilterParams,
  fetchConfiguration,
};
